.formatted-text {
  ul {
    padding-left: 20px;

    li {
      font-weight: 400;
      margin-bottom: 3px;
    }
  }

  p {
    font-weight: 400;
    margin-bottom: 10px;
  }
}