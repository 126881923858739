.table {
  width: 100%;
  border-collapse: collapse;

  &-wrapper {
    overflow-y: hidden;
    overflow-x: auto;
    max-width: 100%
  }

  tr {
    th,
    td {
      border-bottom: 1px solid rgb(201, 201, 201);
      border-left: 1px solid rgb(201, 201, 201);

      &:last-child {
        border-right: 1px solid rgb(201, 201, 201);
      }
    }

    th {
      padding: 8px;
      background-color: rgb(201, 201, 201);
    }

    td {
      padding: 4px;

      &.text-center {
        text-align: center;
      }
    }
  }
}