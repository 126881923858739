.footer {
  background: $c-dark-gray;
  @apply mt-auto py-5 md:py-14;

  &__wrapper {
    width: 100%;
  }
  &__top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    @include to(800) {
      margin-bottom: 30px;
    }
  }

  &__logo {
    @include to(800) {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }

  &__logo .logo__img {
    @include to(800) {
      margin-bottom: 30px;
      margin-right: 0;
    }
  }

  &__logo .logo__title {
    &:after {
      @include to(800) {
        left: 0;
        top: -15px;
        transform: none;
        width: 30px;
        height: 1px;
      }
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    align-items: center;

    @include to(800) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .contacts {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 43px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 40px;

    @include to(1200) {
      flex-direction: column;
      align-items: flex-start;
    }
    @include to(800) {
      padding-bottom: 30px;
      margin-bottom: 20px;
    }

    &__phone {
      margin-right: 90px;

      @include to(800) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }

    &__name {
      display: block;
      color: $c-gray-light;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
    }

    &__address {
    }

    &__text {
      color: $c-gray-light;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;

      @include to(800) {
        line-height: 16px;
      }
    }
  }

  &__left-col {
    display: flex;
    align-items: flex-start;

    @include to(1200) {
      margin-bottom: 30px;
    }
    @include to(800) {
      flex-direction: column;
    }
  }

  .social {
    display: flex;
    align-items: center;

    &__item {
      width: 50px;
      height: 50px;
      background: #000;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  &__copyright {
    font-size: 12px;
    line-height: 18px;
    margin-right: 68px;
    color: $c-gray-light;

    @include to(1660) {
      margin-right: 20px;
      font-size: 9px;
      line-height: 10px;
    }
    @include to(800) {
      margin-bottom: 2px;
      font-size: 12px;
      line-height: 18px;
    }
  }
  &__rights {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: #fff;
    transition: all 0.5s;
    border-bottom: 1px solid transparent;
    margin-right: 68px;
    // margin-right: auto;

    @include to(1660) {
      font-size: 9px;
      line-height: 10px;
      margin-right: 20px;
    }
    @include to(800) {
      margin-bottom: 2px;
      // margin-bottom: 12px;
      font-size: 12px;
      line-height: 18px;
    }

    &:hover {
      color: $c-bronze-gold;
      border-bottom: 1px solid $c-bronze-gold;
    }
  }
  &__agreement {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: #fff;
    transition: all 0.5s;
    border-bottom: 1px solid transparent;
    margin-right: auto;

    @include to(1660) {
      font-size: 9px;
      line-height: 10px;
    }
    @include to(800) {
      margin-bottom: 12px;
      font-size: 12px;
      line-height: 14px;
    }

    &:hover {
      color: $c-bronze-gold;
      border-bottom: 1px solid $c-bronze-gold;
    }
  }
  &__made {
    width: 190px;
  }
}
