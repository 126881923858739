.link {
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #C78E66;
    border-bottom: 1px solid #C78E66;
  }
}

.link-phone {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  transition: all 0.5s;
  display: block;

  &_theme_light {
    color: #fff;
  }

  &:hover {
    color: $c-bronze-gold;
  }
  &:active {
    color: $c-gray-medium;
  }

  @include to(1660) {
    font-size: 16px;
    line-height: 16px;
  }

}
.link-email {
  display: block;
  margin-bottom: 5px;
  &:hover {
    color: $c-bronze-gold;
  }
  &:active {
    color: $c-gray-medium;
  }
}