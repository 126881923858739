.advantages {
  padding: 70px 0 60px;
  background-color: #F2F2F2;

  @include to(1440) {
    padding: 35px 0 30px;
  }

  h2 {
    font-size: 54px;
    color: #181B1D;
    text-align: center;
    margin-bottom: 30px;

    @include to(1440) {
      font-size: 34px;
    }

    @include to(767) {
      font-size: 28px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    @include to(1440) {
      justify-content: center;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: calc(20% - 18px);
    margin-right: 18px;

    @include to(1440) {
      width: calc(33.333% - 18px);
      margin-bottom: 18px;
    }

    @include to(767) {
      width: calc(50% - 18px);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__top {
    position: relative;
    padding-top: 10px;
    background-color: #30363A;
  }

  &__svg {
    width: 120px;
    height: 90px;
    margin: 0 auto;
  }

  &__overlay {
    width: 100%;
    height: 100%;
    font-size: 50px;
    font-weight: 900;
    color: #fff;
    text-align: center;

    @include to(500) {
      font-size: 30px;
    }
  }

  &__text {
    flex: 1 0 auto;
    font-size: 18px;
    padding: 20px;
    background-color: #fff;
    font-weight: 500;

    @include to(767) {
      font-size: 16px;
    }

    span {
      color: #C78E66;
    }
  }

}