.cap {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    -webkit-transition: visibility .3s, opacity .3s;
    transition: visibility .3s, opacity .3s;
    
    &_show {
        visibility: visible;
        opacity: 1
    }
    &__close {
        position: absolute;
        z-index: 12;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background: #fff;
        cursor: pointer;
        &:after,
         :before {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 10px);
            width: 20px;
            height: 2px;
            background: #30363a
        }
        &:before {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg)
        }
        &:after {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg)
        }
    }
    & .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        height: 100%;
        overflow-y: auto;
        // overflow-x: hidden;
        @include from(1025) {
            padding-right: 80px;
            padding-left: 80px;
        }
        @include from(1450) {
            padding-right: 110px;
            padding-left: 110px
        }
        @include to(1024) {
            padding-right: 20px;
            padding-left: 20px;
        }
        @include to(1024) {
            @include from(481) {
                padding-left: 80px;
                padding-right: 80px
            }
        }
    }
    &__content {
        width: 100%;
        margin: 0 0 20px 0;
        @include from(1025) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin: 0 0 40px -20px
        }
    }
    &__name {
        font-size: 11px;
        line-height: 18px;
        color: #7d8082;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-top: 20px;
        @include from(1025) {
            font-size: 12px
        }
        @include from(1450) {
            margin-top: 35px
        }
    }
    &__title {
        font-weight: 300;
        font-size: 20px;
        line-height: 1;
        text-transform: uppercase;
        margin-top: 20px;
        @include from(481) {
            font-size: 30px
        }
        @include from(1450) {
            font-size: 40px;
            margin-top: 40px
        }
    }
    &__text {
        margin-top: 20px;
        p {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0
            }
        }
        @include from(1025) {
            line-height: 1.7;
            margin-top: 30px;
            & p {
                margin-bottom: 24px
            }
        }
        @include from(1450) {
            margin-top: 35px;
            & p {
                margin-bottom: 30px
            }
        }
    }
    &-about {
        &__img {
            margin-left: -20px;
            margin-right: -20px;
            overflow: hidden;
            position: relative;
            @include from(1025) {
                height: 100%;
                margin-top: 0;
                margin-left: -80px;
                margin-right: 0;
                &:before {
                    padding-top: 0
                }
            }
            @include from(481) {
                height: 480px;
                margin-top: 10px;
                margin-left: -80px;
                margin-right: -80px
            }
            @include from(1025) {
                height: 100%;
                margin-top: 0;
                margin-left: -80px;
                margin-right: 0;
                &:before {
                    padding-top: 0
                }
                @include from(1450) {
                    margin-left: -110px
                }
            }
        }
        @include from(1025) {
            & .row {
                height: 100%
            }
        }
    }
    &__close {
        position: absolute;
        z-index: 12;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background: #fff;
        cursor: pointer;
        border: none;
        @include from(1025) {
            top: 30px;
            right: 32px;
            width: 30px;
            height: 30px
        }
        @include from(1450) {
            top: 38px;
            right: 42px;
            width: 40px;
            height: 40px
        }
        &:after,
        &:before {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 10px);
            width: 20px;
            height: 2px;
            background: #30363a;
            @include from(1025) {
                top: 15px;
                left: -5px;
                width: 40px
            }
            @include from(1450) {
                top: 21px;
                left: -7px;
                width: 55px
            }
        }
        &:before {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg)
        }
        &:after {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg)
        }
    }
}
