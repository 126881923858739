.section-about {
    position: relative;
    height: 100vh;

    @include to(1200) {
        padding: 140px 0;
    }
    @include to(800) {
        padding: 85px 0px;
    }

    &__img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
}

.about-block {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include to(800) {
        align-items: center;
    }

    &__title {
        margin-bottom: 50px;

        @include to(800) {
            text-align: center;
        }
    }   

    &__text {
        color: $c-gray-divider;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 50px;
        max-width: 50%;

        @include to(800) {
            text-align: center;
            max-width: 100%;
            font-size: 15px;
            line-height: 24px;
        }
    }
}