$color_1: #ffffff;
$color_2: transparent;
$color_3: white;
$color_4: #c0c0c0;
$color_5: black;
$font-family_1: inherit;
$background-color_1: #eee;
$border-color_1: #c78e66;
$border-color_2: white;

@-webkit-keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}
@keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}
.btn-credit {
  position: relative;
  height: 45px;
  display: inline-flex;
  place-items: center;
  padding: 0 20px;
  text-transform: uppercase;
  font-family: $font-family_1;
  font-weight: 500;
  font-size: 16px;
  border: none;
  overflow: hidden;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  > span {
    position: relative;
  }
  &:hover {
    &::before {
      content: "";
      opacity: 0.5;
      display: block;
      width: 30px;
      height: 200px;
      background: -webkit-gradient(
          linear,
          right top,
          left top,
          from(rgba(255, 255, 255, 0)),
          color-stop(rgba(255, 255, 255, 0.8)),
          to(rgba(255, 255, 255, 0))
        )
        no-repeat -2px 0;
      background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0)
        )
        no-repeat -2px 0;
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3ffffff', endColorstr='#b3ffffff',GradientType=0 );
      position: absolute;
      top: -40px;
      left: -30px;
      z-index: 1;
      transform: rotate(45deg);
      transition: all 0.1s;
      animation-name: slideme;
      animation-duration: 3s;
      animation-delay: 0.05s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
}
.btn-credit--primary {
  background: var(--c-primary);
  border-color: $border-color_1;
  color: $color_1;
  &:hover {
    background: var(--c-primary-light);
  }
}
.btn-credit--lg {
  height: 55px;
}
.form_item {
    select {
  outline: none;
  transition: 0.2s ease-in-out;
  border: 1px solid #eee;
  border-bottom-width: 2px;
  appearance: none;
  background-image: url('data:image/svg+xml;utf-8, <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="m16 4.7-.78-.79-7 7.1-7-7.1-.78.79 7.8 7.9z" fill="%23c78e66"/></svg>');
  background-position: center right 10px;
  background-repeat: no-repeat;
  background-size: 20px;
  cursor: pointer;
  &:active {
    border-color: $border-color_1;
  }
  &:focus {
    border-color: $border-color_1;
  }
  &:hover {
    border-color: $border-color_1;
  }
}
}

.block3 {
  .block3_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    .block3_content_left {
      width: 60%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column;
      flex-flow: column;
      text-align: center;
      margin-bottom: 2em;
      img {
        width: 100%;
      }
      p {
        &:nth-of-type(1) {
          color: $color_3;
          text-transform: uppercase;
          font-weight: 500;
          margin-bottom: 20px;
        }
        &:nth-of-type(2) {
          color: $color_4;
          line-height: 1;
          font-weight: 400;
        }
      }
    }
    .block3_content_right {
      width: 35%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column;
      flex-flow: column;
      form {
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        .form_card {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          .form_item {
            &:nth-of-type(1) {
              width: 50%;
            }
            &:nth-of-type(2) {
              width: 37%;
            }
          }
        }
        .form_item {
          width: 100%;
          input {
            &::-webkit-input-placeholder {
              font-size: 16.6px;
              color: $color_5;
              line-height: 1.2;
              font-weight: 400;
            }
            &:-ms-input-placeholder {
              font-size: 16.6px;
              color: $color_5;
              line-height: 1.2;
              font-weight: 400;
            }
            &::-ms-input-placeholder {
              font-size: 16.6px;
              color: $color_5;
              line-height: 1.2;
              font-weight: 400;
            }
            width: 100%;
            padding: 15px 28px;
            background-color: $background-color_1;
            font-size: 16.6px;
            color: $color_5;
            line-height: 1.2;
            font-weight: 400;
          }
          select {
            width: 100%;
            padding: 15px 28px;
            background-color: $background-color_1;
            font-size: 16.6px;
            color: $color_5;
            line-height: 1.2;
            font-weight: 400;
            option {
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }
          p {
            font-size: 15.2px;
            color: $color_3;
            line-height: 1.2;
            font-weight: 500;
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}
.block3.block3--credit {
  .block3_content {
    flex-direction: row;
    align-items: end;
    .block3_content_right {
      margin-bottom: 2em;
    }
    .block3_content_left {
      text-align: left;
      img {
        max-height: 400px;
        display: flex;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .block3_content_left {
    > img {
      margin-top: 30px;
    }
  }
  [type="submit"] {
    margin-top: 14px;
  }
}

.with-arrow {
  position: relative;
  padding-right: 45px !important;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% - 6px);
    right: 20px;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: border 0.2s;
    transition: border 0.2s;
  }
  &:hover {
    &::after {
      border-color: $border-color_1;
    }
  }
}
.btn-credit--secondary.with-arrow {
  &:not(:hover) {
    &::after {
      border-color: $border-color_2;
    }
  }
}
.btn-credit--primary.with-arrow {
  &:hover {
    &::after {
      border-color: $border-color_2;
    }
  }
}
@media (pointer: coarse) {
  .btn-credit--primary {
    &:hover {
      &:not(.active) {
        background: var(--c-primary);
      }
    }
  }
}
@media (min-width: 768px) {
  .btn-credit--lg {
    height: 65px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .block3 {
    .block3_content {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column;
      flex-flow: column;
      .block3_content_left {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
      .block3_content_right {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        form {
          .form_item {
            select {
              margin-bottom: 15px;
            }
            input {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  .block3.block3--credit {
    .block3_content {
      flex-direction: column;
    }
  }
}
@media only screen and (min-width: 1201px) {
  .block3.block3--credit {
    .block3_content {
      .block3_content_left {
        margin-bottom: 1rem;
      }
      .block3_content_right {
        margin-bottom: 1rem;
      }
    }
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .block3 {
    .block3_content {
      .block3_content_left {
        width: 57%;
      }
      .block3_content_right {
        width: 40%;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .block3 {
    .block3_content {
      .block3_content_left {
        width: 100%;
      }
      .block3_content_right {
        width: 100%;
        form {
          .form_item {
            select {
              padding: 12px 28px;
            }
            input {
              padding: 12px 28px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1920px) {
  .block3 {
    .block3_content {
      .block3_content_left {
        p {
          &:nth-of-type(1) {
            font-size: 35px;
          }
          &:nth-of-type(2) {
            font-size: 30px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1920px) {
  .block3 {
    .block3_content {
      .block3_content_left {
        p {
          &:nth-of-type(1) {
            font-size: calc(24px + 11 * ((100vw - 320px) / 1600));
          }
          &:nth-of-type(2) {
            font-size: calc(20px + 10 * ((100vw - 320px) / 1600));
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .block3 {
    .block3_content {
      .block3_content_left {
        p {
          &:nth-of-type(1) {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .block3 {
    .block3_content {
      .block3_content_right {
        width: 80%;
        width: 100%;
      }
    }
  }
  .block3.block3--credit {
    .block3_content {
      .block3_content_right {
        margin-bottom: 0;
      }
    }
  }
}
@media only screen and (max-width: 460px) {
  .block3 {
    .block3_content {
      .block3_content_right {
        form {
          .form_card {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-flow: column;
            flex-flow: column;
            .form_item {
              &:nth-of-type(1) {
                width: 100%;
              }
              &:nth-of-type(2) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .block3 {
    .block3_content {
      .block3_content_right {
        form {
          .form_card {
            .form_item {
              &:nth-of-type(2) {
                width: 45%;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1201px) {
  .block3.block3--credit {
    .block3_content {
      .block3_content_right {
        form {
          .form_item {
            margin-bottom: 2.6em;
          }
        }
      }
    }
  }
}
