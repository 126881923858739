.banner-credit {
  padding: 65px 0;
  background-image: url('../img/credit/image87.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  &-wrapper {
    position: relative;

    img {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      width: 840px;
      height: 489px;

      @include to(1200) {
        display: none;
      }
    }
  }

  &__inner {
    width: 100%;
    padding: 29px 16px;
    background: rgba(48, 54, 58, 0.6);
    backdrop-filter: blur(20px);
  }

  &__pr {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 15px;

    div {
      padding: 5px 10px;
      color: #C78E66;
      background-color: rgba(199, 142, 102, .1);
    }
  }

  &__text_small {
    display: inline-block;
    vertical-align: bottom;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }

  &__text_big {
    display: inline-block;
    vertical-align: bottom;
    font-size: 84px;
    line-height: .8;
    font-weight: 900;
    color: #C78E66;

    @include to(767) {
      font-size: 54px;
    }
  }

  &__text_fat {
    display: inline-block;
    vertical-align: bottom;
    font-size: 24px;
    font-weight: 800;
    color: #fff;
  }

  &__text_middle {
    font-size: 22px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__text_over-middle {
    font-size: 22px;
    color: #fff;
    margin-bottom: 10px;
  }

  .btn {
    display: inline-flex;
    width: auto;
    background-color: #C78E66;

    &:hover {
      .btn__text_theme_light {
        color: #30363A;
      }
    }
  }
}