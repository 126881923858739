.flex-blocks {
  width: 100%;
  min-height: 180px;
  overflow: hidden;

  &-wrapper {
    background-color: #181B1D;
  }

  &-inner {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(20% - 1px);
    min-width: 180px;
    min-eight: 180px;
    padding: 20px;
    border-right: 1px solid rgba(#C78E66, .6);
    background-color: #181B1D;
    overflow: hidden;
    transition: background-color .2s ease-in-out;
    cursor: pointer;

    &:first-child {
      border-left: 1px solid rgba(#C78E66, .6);
    }

    &:hover {
      background-color: #C78E66;

      .flex-blocks__icon {
        opacity: 1;
      }

      .flex-blocks__number {
        color: rgba(255, 255, 255, 0.6);
      }

      .flex-blocks__icon {
        transform: translateX(-50%) translateY(-100%) rotate(0deg);
        img {
          filter: invert(99%) sepia(99%) saturate(2%) hue-rotate(209deg) brightness(105%) contrast(100%);
        }
        svg {
          fill: #fff;
        }
      }
    }
  }

  &__number {
    font-size: 54px;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.1);
    transition: color .2s ease-in-out;
  }

  &__text {
    font-size: 20px;
    color: #fff;
    margin-top: auto;

    @include to(1200) {
      font-size: 14px;
    }

    span {
      font-weight: 600;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateX(40%) translateY(-50%) rotate(-45deg);
    margin-top: auto;
    transition: transform .2s ease-in-out;
    img {
      width: 50px;
      height: 50px;
      filter: invert(66%) sepia(60%) saturate(316%) hue-rotate(341deg) brightness(82%) contrast(87%);
      transition: fill .2s ease-in-out;
    }
    svg {
      width: 50px;
      height: 50px;
      fill: #C78E66;
      transition: fill .2s ease-in-out;
    }
  }

  &__image {
    position: absolute;
    bottom: 0;
    right: 0;

    img {
      width: 160px;
      height: 100%;

      @include to(1200) {
        width: 130px;
      }
    }
  }
}
