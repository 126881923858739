#success,
#error {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $c-dark-gray;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 25px;
  max-width: 340px;
  min-width: 290px;
  z-index: 100000;
  border: none;
  box-shadow: 0 0 5px 3px rgba(43, 43, 43, 0.28);

  &.show {
    display: flex;
  }

  .popup__subtitle {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
    color: #fff;
    text-align: center;
  }
}
