.lx-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $c-very-dark;
  z-index: 110;
  padding: 80px 120px 50px 120px;

  @include to(768) {
    padding: 20px;
    padding-bottom: 50px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @include to(768) {
      margin-bottom: 20px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    color: $c-gray-medium;

    @include to(768) {
      font-size: 12px;
    }
  }

  &__close {
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    cursor: pointer;

    @include to(768) {
      width: 20px;
      height: 20px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__cards {
    width: 100%;
    height: 60vh;
    display: flex;
    margin-bottom: 30px;

    @include to(768) {
      margin-bottom: 0;
    }

    .slick-slide {
      margin: 0 60px;
    }

    .slick-list {
      margin: 0 -60px;
    }
  }

  &__card {
    width: 50%;

    &:first-child {
      margin-right: 60px;
    }

    .card {
      &__img {
        margin-bottom: 40px;

        @include to(768) {
          width: 100vw;
          transform: translateX(-20px);
          order: 2;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__title {
        font-size: 40px;
        font-weight: 300;
        margin-bottom: 30px;
        text-transform: uppercase;
        color: #fff;

        @include to(768) {
          font-size: 18px;
          order: 1;
        }
      }

      &__line {
        display: block;
        margin-bottom: 30px;
        width: 105px;
        height: 1px;
        background-color: $c-bronze-gold;

        @include to(768) {
          display: none;
        }
      }

      &__text {
        font-size: 1em;
        line-height: 27px;
        color: #fff;

        @include to(768) {
          font-size: 14px;
          order: 3;
          overflow-y: scroll;
          max-height: 190px;
        }

        @include to(480) {
          font-size: 14px;
          order: 3;
          overflow-y: scroll;
          max-height: 20vh;
        }
      }

      &__subtitle {
        margin-bottom: 10px;
      }

      &__list {
        li {
          color: #fff;
          margin-left: 25px;

          &::marker {
            margin-left: 25px;
          }
        }
      }
    }
  }

  &__navigation {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .navigation-btn {
      position: static;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      background-color: $c-bronze-gold;
      border-radius: 50%;
      padding: 0;
      transform: translate(0);
      cursor: pointer;

      img {
        width: 40%;
        height: 40%;
      }

      @include to(768) {
        width: 40px;
        height: 40px;
      }

      &:first-child {
        transform: rotate(180deg);
      }
    }

    .slick-disabled {
      opacity: 0.5;
      background-color: $c-dark-gray;
    }

    .navigation-count {
      margin: 0 20px;
      font-size: 18px;
      color: #fff;
      white-space: nowrap;
    }
  }

  &__slide {
    display: flex !important;
    align-self: stretch;
    flex: 1;
    height: auto !important;
  }

  &__slide-three {
    .lx-popup__card:nth-child(2) {
      margin-right: 60px;
    }
  }
}

.dynamic-card {
  width: 50%;

  &:first-child {
    margin-right: 60px;
  }
}

.lx-popup__navigation-dynamic {
  display: none;

  @include to(768) {
    display: flex;
  }
}

.lx-popup__navigation-safety-mobile {
  display: none;

  @include to(768) {
    display: flex;
  }
}

.lx-popup__navigation-safety-desktop {
  display: flex;

  @include to(768) {
    display: none;
  }
}
