.range {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &__bar_below {
    margin-top: 25px;
    margin-bottom: 5px;
    flex-basis: 100%;
    flex-shrink: 0;
  }

  &__from,
  &__to {
    position: static;
    width: calc(50% - 10px);
  }

  input {
    display: block;
    width: 100%;
    background: #fff;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #d3d3d3;
    padding: 6px 8px;
    min-height: 32px;
    outline: none;
    color: #262626;
    transition: border .25s cubic-bezier(.55, .055, .675, .19);

    div {
      font-size: 16px;
    }

    &:hover {
      border-color: #b6b6b6;
    }
  }

  &__bar .noUi-handle-lower::before,
  &__bar .noUi-handle-upper::before {
    display: none;
  }

  .noUi-handle-lower {
    position: absolute;
    top: -7px !important;
    left: 0 !important;
    width: 16px !important;
    height: 16px !important;
    border-radius: 3px;
  }

  .noUi-handle-upper {
    position: absolute;
    top: -7px !important;
    left: -15px !important;
    width: 16px !important;
    height: 16px !important;
    border-radius: 3px;
  }

  /*! nouislider - 10.0.0 - 2017-05-28 14:52:48 */
  .noUi-target,
  .noUi-target * {
    touch-action: none;
    user-select: none;
    box-sizing: border-box;
  }

  .noUi-target {
    position: relative;
    direction: ltr;
  }

  .noUi-base {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 4px;
    background: #d3d3d3;
  }

  .noUi-connect {
    position: absolute;
    right: 0;
    top: -1px;
    left: 0;
    bottom: 0;
    height: 4px;
    background: #C78E66;
  }

  .noUi-origin {
    position: absolute;
    height: 0;
    width: 0
  }

  .noUi-handle {
    z-index: 1;
    position: relative;
    background: #C78E66;
  }

  .noUi-state-tap .noUi-connect,
  .noUi-state-tap .noUi-origin {
    transition: top .3s, right .3s, bottom .3s, left .3s
  }

  .noUi-state-drag * {
    cursor: inherit !important
  }

  .noUi-base,
  .noUi-handle {
    transform: translate3d(0, 0, 0)
  }

  .noUi-horizontal {
    height: 18px
  }

  .noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    left: -17px;
    top: -6px
  }

  .noUi-vertical {
    width: 18px
  }

  .noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px
  }

  .noUi-target {
    background: #FAFAFA;
    border-radius: 4px;
  }

  .noUi-draggable {
    cursor: ew-resize
  }

  .noUi-vertical .noUi-draggable {
    cursor: ns-resize
  }

  [disabled] .noUi-connect {
    background: #B8B8B8
  }

  [disabled] .noUi-handle,
  [disabled].noUi-handle,
  [disabled].noUi-target {
    cursor: not-allowed
  }

  .noUi-pips,
  .noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box
  }

  .noUi-pips {
    position: absolute;
    color: #999
  }

  .noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center
  }

  .noUi-value-sub {
    color: #ccc;
    font-size: 10px
  }

  .noUi-marker {
    position: absolute;
    background: #CCC
  }

  .noUi-marker-large,
  .noUi-marker-sub {
    background: #AAA
  }

  .noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%
  }

  .noUi-value-horizontal {
    transform: translate3d(-50%, 50%, 0)
  }

  .noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px
  }

  .noUi-marker-horizontal.noUi-marker-sub {
    height: 10px
  }

  .noUi-marker-horizontal.noUi-marker-large {
    height: 15px
  }

  .noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%
  }

  .noUi-value-vertical {
    transform: translate3d(0, 50%, 0);
    padding-left: 25px
  }

  .noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px
  }

  .noUi-marker-vertical.noUi-marker-sub {
    width: 10px
  }

  .noUi-marker-vertical.noUi-marker-large {
    width: 15px
  }

  .noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap
  }

  .noUi-horizontal .noUi-tooltip {
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%
  }

  .noUi-vertical .noUi-tooltip {
    transform: translate(0, -50%);
    top: 50%;
    right: 120%
  }
}