.section-seven {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9051995798319328) 0%,
      rgba(0, 0, 0, 0.5326505602240896) 14%,
      rgba(255, 255, 255, 0) 40%
    ),
    url("/static/img/lx/section-7.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 110px;
  display: flex;
  justify-content: space-between;

  @include to(480) {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0) 70%),
      url("/static/img/lx/mobile/7.jpg");
  }

  .section-info {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    .btn {
      max-width: 180px;

      @include to(768) {
        max-width: 145px;
        margin: auto auto 0;
        background-color: transparent;
        color: #fff;
      }
    }

    h2 {
      @include to(768) {
        font-size: 30px;
        text-align: center;
      }
    }

    p {
      display: inline-block;

      @include to(768) {
        display: none;
      }
    }
  }

  .section-menu__menu {
    li {
      &:first-child {
        font-size: 14px;
        opacity: 0.7;
      }

      &:nth-child(5) {
        font-size: 20px;
        opacity: 1;
      }
    }
  }

  .section-menu__progress {
    &:before {
      top: 196px;
    }
  }
}
