.txl {
    &-block {
        &-container {
            width: 100%;
            position: relative;
            // padding-top: 60px;
            img {
                width: 100%;
            }
            @include to(505) {
                display: none;
            }
            &-mobile {
                display: none;
                @include to(505) {
                  position: relative;
                    display: block;
                    p {
                        font-size: 40px;
                    }
                    span {
                        font-size: 14px;
                    }

                    & > img {
                      width: 100%;
                      height: auto;
                    }
                }
            }
            &-view {
                position: relative;
                width: 100%;
                height: 100%;
                left: 0px;
                top: 0px;
                &-years {
                    position: absolute;
                    width: 123px;
                    height: 45px;
                    left: 119px;
                    top: 40px;
                }
                &-desktop {
                    display: block;
                    min-height: 435px;
                    background: #30363A;
                    @include to(768) {
                      min-height: 360px;
                    }

                    @include to(360) {
                        display: none;
                    }

                    img {
                      @include to(768) {
                        display: none;
                      }
                    }
                }
                &-mobile {
                    display: none;
                    @include to(360) {
                        display: block;
                        background: linear-gradient(180deg, rgba(24, 27, 29, 0.5), transparent), url('/static/img/txl-new/section-txl-container-mobile.jpg');
                        width: 360px;
                        height: 510px;
                    }
                    button {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 16px 30px;
                        gap: 20px;
                        position: absolute;
                        width: 218px;
                        height: 50px;
                        left: 71px;
                        top: 430px;
                        /* White */
                        border: 1px solid #FFFFFF;
                        background: transparent;
                        color: #fff;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        text-align: center;
                        font-weight: 600;
                        font-size: 11px;
                        line-height: 18px;
                    }
                }
                img {
                    width: 100%;
                }
                &-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 60px;
                    position: absolute;
                    width: 35.5%;
                    height: 418px;
                    left: 50%;
                    top: 30%;
                    @include to(1000) {
                        top: 10%;
                    }
                    @include to(768) {
                      left: 18px !important;
                      width: calc(100% - 36px) !important;
                    }
                    h2 {
                        height: 120px;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 60px;
                        line-height: 60px;
                        text-transform: uppercase;
                        color: #FFFFFF;
                        @include from(1920) {
                            width: 626px;
                        }
                        @include to(1440) {
                          height: auto;
                          font-size: 24px;
                          line-height: 1.1;
                        }
                        @include to(1280) {
                            width: auto;
                        }
                        @include to(768) {
                            width: auto;
                        }
                        @include to(414) {
                            font-size: 20px;
                            line-height: 20px;
                            height: auto;
                        }
                    }
                    p {
                        width: 682px;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 26px;
                        color: #FFFFFF;
                        margin-bottom: 60px;
                        @include to(1280) {
                            font-size: 12px;
                            margin-bottom: 0;
                        }
                        @include to(414) {
                            font-size: 12px;
                            line-height: 16px;
                            height: auto;
                        }
                    }
                    button {
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 16px 30px;
                        gap: 20px;
                        width: 218px;
                        height: 50px;
                        border: 1px solid #FFFFFF;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 11px;
                        line-height: 18px;
                        /* identical to box height, or 164% */
                        text-align: center;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        /* White */
                        color: #FFFFFF;
                        background: transparent;
                        @include to(414) {
                            display: none;
                        }
                    }
                    @include to(1280) {
                        height: 278px;
                    }
                    @include to(768) {
                        left: 40%;
                        width: 60%;
                        gap: 20px
                    }
                }
                &-left {
                    display: flex;
                    @include from(1000) {
                        flex-flow: row;
                    }
                    @include to(1000) {
                        flex-flow: column;
                    }
                    h2 {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 300;
                        line-height: 50px;
                        /* or 100% */
                        text-transform: uppercase;
                        /* White */
                        color: #FFFFFF;
                        /* Inside auto layout */
                        flex: none;
                        order: 0;
                        align-self: stretch;
                        flex-grow: 0;
                        font-size: 50px;
                        @include to(1440) {
                            font-size: 24px;
                            line-height: 30px;
                        }
                        @include to(1280) {
                            margin-bottom: 0;
                        }
                        @include from(1920) {
                            height: 150px;
                            font-size: 50px;
                            margin-bottom: 40px;
                        }
                    }
                    p {
                        // width: 682px;
                        height: 186px;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 170%;
                        color: #DEDEDE;
                        @include to(1280) {
                            font-size: 12px;
                        }
                        @include to(1000) {
                            height: auto;
                        }
                    }
                    &-image {
                        width: 50%;
                        @include to(1000) {
                            width: 100%;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &-description {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding: 83px 139px;
                        position: relative;
                        width: 50%;
                        top: 0px;
                        background: #30363A;
                        @include to(1000) {
                            width: 100%;
                            padding: 70px;
                        }
                        @include to(1440) {
                            padding: 50px;
                        }
                        @include from(1920) {
                            padding: 0px 139px;
                        }
                        @include to(414) {
                            padding: 30px;
                        }
                        @include to(360) {
                            padding: 20px;
                        }
                        h2 {
                            margin-bottom: 40px;
                            @include to(360) {
                                font-weight: 300;
                                font-size: 20px;
                                line-height: 120%;
                                /* or 24px */
                                text-transform: uppercase;
                                /* White */
                                color: #FFFFFF;
                                margin-bottom: 0;
                            }
                            @include to(1440) {}
                        }
                        p {
                            @include to(360) {
                                display: none;
                            }
                        }
                    }
                }
                &-right {
                    display: flex;
                    @include from(1000) {
                        flex-flow: row;
                    }
                    @include to(1000) {
                        flex-flow: column;
                    }
                    h2 {
                        // width: 682px;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 300;
                        line-height: 50px;
                        /* or 100% */
                        text-transform: uppercase;
                        /* White */
                        color: #FFFFFF;
                        /* Inside auto layout */
                        flex: none;
                        order: 0;
                        align-self: stretch;
                        flex-grow: 0;
                        font-size: 50px;
                        @include to(1280) {
                            margin-bottom: 0;
                        }
                        @include to(1440) {
                            font-size: 24px;
                            line-height: 30px;
                        }
                        @include from(1920) {
                            height: 150px;
                            font-size: 50px;
                            margin-bottom: 40px;
                        }
                    }
                    p {
                        // width: 682px;
                        height: 186px;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 170%;
                        color: #DEDEDE;
                        @include to(1280) {
                            font-size: 12px;
                        }
                        @include to(1000) {
                            height: auto;
                        }
                    }
                    display: flex;
                    &-image {
                        width: 50%;
                        @include to(1000) {
                            width: 100%;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &-description {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding: 83px 139px;
                        position: relative;
                        width: 50%;
                        top: 0px;
                        background: #181B1D;
                        @include to(1000) {
                            width: 100%;
                            padding: 70px;
                            order: 1;
                        }
                        @include to(1440) {
                            padding: 50px;
                        }
                        @include from(1920) {
                            padding: 0px 139px;
                        }
                        @include to(414) {
                            padding: 30px;
                        }
                        @include to(360) {
                            padding: 20px;
                        }
                        h2 {
                            margin-bottom: 40px;
                            @include to(360) {
                                font-weight: 300;
                                font-size: 20px;
                                line-height: 120%;
                                /* or 24px */
                                text-transform: uppercase;
                                /* White */
                                color: #FFFFFF;
                                margin-bottom: 0;
                            }
                        }
                        p {
                            @include to(360) {
                                display: none;
                            }
                        }
                    }
                }
            }
            &-test-drive {
                position: relative;
                width: 100%;
                left: 0px;
                top: 0px;
                background: linear-gradient(90deg, rgba(24, 27, 29, 0.8) 0%, rgba(24, 27, 29, 0) 100%);
                &-desktop {
                    display: block;
                    >img {
                        width: 100%;
                    }
                    @include to(360) {
                        display: none;
                    }
                }
                &-mobile {
                    display: none;
                    @include to(360) {
                        display: block;
                        background: linear-gradient(0deg, rgba(24, 27, 29, .5), rgba(24, 27, 29, .5)), url('/static/img/txl-new/text-img_huba7845d67d36420b0c6f029a4048c6e7_4584894_1920x0_resize_q80_lanczos 2.jpg');
                        width: 360px;
                        height: 510px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 0px;
                        gap: 30px;
                    }
                    h2 {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 30px;
                        line-height: 30px;
                        /* or 100% */
                        text-align: center;
                        text-transform: uppercase;
                        /* White */
                        color: #FFFFFF;
                        position: absolute;
                        width: 320px;
                        height: 60px;
                        left: 20px;
                        top: 79px;
                    }
                    button {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 11px 20px;
                        gap: 20px;
                        position: absolute;
                        width: 206px;
                        height: 40px;
                        left: 77px;
                        top: 440px;
                        /* White */
                        border: 1px solid #FFFFFF;
                        background: transparent;
                        font-weight: 600;
                        font-size: 9px;
                        line-height: 18px;
                        /* identical to box height, or 200% */
                        text-align: center;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        /* White */
                        color: #FFFFFF;
                    }
                }
                &-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 60px;
                    position: absolute;
                    width: 425px;
                    height: 230px;
                    left: 13%;
                    top: 35.5%;
                    @include to(1000) {
                        left: 10%;
                    }
                    @include to(768) {
                        top: 26.5%;
                        gap: 15px;
                        width: auto;
                    }
                    @include to(414) {
                        top: 10%;
                        gap: 88px;
                        left: 0;
                        align-items: center;
                    }
                    h2 {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 60px;
                        line-height: 60px;
                        /* or 100% */
                        text-transform: uppercase;
                        /* White */
                        color: #FFFFFF;
                        margin-bottom: 60px;
                        @include to(1440) {
                            font-size: 24px;
                            margin-bottom: 0;
                        }
                        @include to(360) {
                            font-size: 20px;
                            top: 10%;
                            width: auto;
                        }
                    }
                    button {
                        box-sizing: border-box;
                        /* Auto layout */
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 16px 30px;
                        gap: 20px;
                        height: 50px;
                        /* White */
                        border: 1px solid #FFFFFF;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 11px;
                        line-height: 18px;
                        /* identical to box height, or 164% */
                        text-align: center;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        /* White */
                        color: #FFFFFF;
                        background: transparent;
                        @include to(414) {
                            bottom: 0;
                            padding: 0 15px;
                            height: 30px;
                        }
                    }
                }
            }
        }
        &-header {
            position: absolute;
            top: 170px;
            left: 5%;
            width: 20%;
            @include to(1000) {
              top: 90px;
              left: 18px;
              
            }
            @include to(414) {
                // display: none;
            }
            h1 {
                font-weight: 300;
                font-size: 37px;
                line-height: 60px;
                color: #fff;
                margin-bottom: 20px;
                width: 100%;
                height: 120px;
                text-transform: uppercase;
                // margin-bottom: 20px;
                @include to(1000) {
                    font-size: 22px;
                    line-height: 50px;
                    height: 104px;
                }
                @include to(768) {
                  width: auto;
                    font-size: 13px;
                    line-height: 30px;
                    margin-bottom: 0px;
                }
                @include to(414) {
                    font-size: 6px;
                    line-height: 20px;
                    margin-bottom: 0px;
                    height: auto;
                }
            }
            p {
                width: 217px;
                height: 29px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 120%;
                color: #FFFFFF;
                margin-bottom: 20px;
                @include to(414) {
                    font-size: 10px;
                    line-height: 12px;
                    margin-bottom: 0px;
                    height: auto;
                }
            }
            span {
                width: 292px;
                height: 35px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 35px;
                line-height: 35px;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #C78E66;
                margin-bottom: 20px;
                @include to(414) {
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 0px;
                    height: auto;
                }
            }
        }
        &-years {
            position: absolute;
            width: 246px;
            height: 90px;
            left: 80%;
            top: 110px;
            @include to(1000) {
                display: none;
            }
            &-mobile {
                display: none;
                @include to(360) {
                    display: block;
                }
            }
        }
        &-options {
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            height: 356px;
            width: 100%;
            background: linear-gradient(0deg, #181B1D 0%, rgba(24, 27, 29, 0) 100%);
            display: flex;
            justify-content: center;
            @include to(768) {
                height: 265px;
            }
            @include to(414) {
                height: 130px;
                bottom: 0;
            }
            &-mobile {
                position: absolute;
                width: 360px;
                height: 292px;
                left: 0px;
                top: 418px;

                @include to(550) {
                  top: auto;
                  left: 50%;
                  bottom: 20px;
                  height: auto;
                  transform: translateX(-50%);
                }
            }
            &-row {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                padding: 0px;
                gap: 10px;
                height: 136px;

                @include to(550) {
                  height: auto;
                }
            }
            &-container {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                padding: 0px;
                gap: 20px;
                position: absolute;
                width: 88%;
                height: 178px;
                top: 89px;
                @include to(1000) {
                    top: unset;
                    bottom: 20px;
                    gap: 10px;
                }
                @include to(505) {
                    flex-wrap: wrap;
                    top: unset;
                    width: 100%;
                    height: auto;
                    bottom: 0;
                }
                &-mobile {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0px;
                    gap: 20px;
                }
            }
            &-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px;
                gap: 10px;
                width: 393.25px;
                height: 178px;
                @include to(505) {
                    width: 45%;
                    height: auto;
                }
                &-mobile {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    gap: 10px;
                    width: 155px;
                    height: 170px;

                    @include to(505) {
                      justify-content: flex-start;
                      height: auto;
                    }
                    p {
                        text-align: center;
                        color: #fff
                    }
                    span {
                        text-align: center;
                        color: #CACACA
                    }
                }
                img {
                    width: 60px;
                    height: 60px;
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 30px;
                    @include to(505) {
                        width: 40px;
                        height: 40px;
                    }
                    @include to(360) {
                        width: 40px;
                        height: 40px;
                        background: rgba(255, 255, 255, 0.1);
                        border-radius: 30px;
                    }
                }
                p {
                    width: 123px;
                    height: 80px;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 80px;
                    line-height: 80px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    @include to(1280) {
                        font-size: 53px;
                    }
                    @include to(768) {
                        height: auto;
                        width: auto;
                        line-height: 22px;
                        font-size: 22px;
                    }
                    @include to(414) {
                        font-size: 17px;
                    }
                }
                span {
                    width: 106px;
                    height: 18px;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: center;
                    color: #7D8082;
                    @include to(1280) {
                        font-size: 12px;
                        color: white;
                    }
                    @include to(414) {
                        width: auto;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}