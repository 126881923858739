.flex-item {
  position: relative;
  margin: 10px;

  &_half {
    width: calc(50% - 20px);

    @include to(767) {
      width: 100%;
    }

    .form__input {
      margin-bottom: 0;
    }

    .btn {
      width: 100%;
      height: 50px;
      min-height: 50px;

      @include to(1660) {
        height: 40px;
        min-height: 40px;
      }
    }
  }

  &_third {
    width: calc(33.3333% - 20px);

    @include to(1024) {
      width: calc(50% - 20px);
    }

    @include to(767) {
      width: 100%;
    }

    .form__input {
      margin-bottom: 0;
    }

    .btn {
      width: 100%;
      height: 50px;
      min-height: 50px;

      @include to(1660) {
        height: 40px;
        min-height: 40px;
      }
    }
  }

  &__abs {
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: 700;
    font-size: 12px;
    transform: translate(-50%, -50%);
  }
}