.more-block {

    &_news {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: $c-base;   
        z-index: -1;
        opacity: 0;
        transition: opacity 0.6s;       
        overflow-y: scroll; 

        &.show {
            z-index: 109;
            opacity: 1;
        }
    }

    &_about {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: $c-base;   
        z-index: -1;
        opacity: 0;
        transition: opacity 0.6s;
        overflow-y: scroll;
        
        &.show {
            z-index: 109;
            opacity: 1;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        
        @include to(1200) {
            flex-direction: column;
        }
    }

    &__img {
        width: 100%;
        min-height: 100vh;
        background: url(../img/popup_about.jpg) no-repeat center;
        background-size: cover;

        @include to(1200) {
            min-height: auto;
            padding-top: 65%;
        }
    }

    &__btn {
        margin-top: 80px;

        @include to(1200) {
            margin-top: 60px;
        }
    }
}

.about-close {
    position: absolute;
    top: 38px;
    right: 42px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    &:before,
    &:after {
        position: absolute;
        content: '';
        top: 21px;
        left: -7px;
        width: 54px;
        height: 2px;
        background:#30363a;

        @include to(1200) {
            top: calc(50% - 1px);
            left: calc(50% - 10px);
            width: 20px;
        }
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    @include to(1200) {
        top: 0;
        right: 0;
        position: fixed;
        background: $c-base;
    }
}

.more-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 85px 140px;
    max-width: 45%;
    height: 100%;

    @include to(1660) {
        padding: 40px 85px;
    }

    @include to(1200) {
        padding: 40px;
        max-width: 100%;
    }
    @include to(800) {
        padding: 20px;
    }

    &__title {
        margin-bottom: 40px;

        @include to(1200) {
            margin-bottom: 0px;
        }
    }

    &__subtitle {
        margin-bottom: 40px;
        text-transform: uppercase;
        font-size: 14px;
        color: $c-gray-medium;

        @include to(1200) {
            margin-bottom: 20px;
        }
        @include to(800) {
            font-size: 10px;
            margin-bottom: 8px;
        }
    }

    &__text {
        font-size: 18px;
        line-height: 25px;

        @include to(1200) {
            font-size: 14px;
            line-height: 20px;
        }
        @include to(800) {
            font-size: 12px;
            line-height: 16px;
        }
    }

    &__list {
        padding-left: 20px;
        margin-bottom: 40px;
    }

    &__par {
        &_bold {
          font-weight: 600;  

          &:not(:last-child) {
              margin-bottom: 5px;
          }
        }
        &:not(:last-child) {
            margin-bottom: 40px;

            @include to(1200) {
                margin-bottom: 15px;
            }
        }
    }
}

.title-desk-block {
    @include to(1200) {
        display: none;
    }
}

.title-mobile-block {
    display: none;

    @include to(1200) {
        display: block;
        padding: 40px;
    }
    @include to(800) {
        padding: 20px;
    }
}