.tabs {
  &__head {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
    overflow-x: auto;

    &-wrapper {
      overflow-x: hidden;
    }
  }

  &__head-item {
    margin: 0 15px;
    white-space: nowrap;
    transition: color .2s ease-in-out, border .2s ease-in-out;
    cursor: pointer;

    &:first-child {
      padding-left: 0;
      margin-left: 0;
    }

    &:last-child {
      padding-right: 0;
      margin-right: 0;
    }

    &:hover {
      color: #C78E66;
    }

    &.active {
      color: #C78E66;
      border-bottom: 1px solid #C78E66;
    }
  }
}