.section-three {
  background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9051995798319328) 0%,
      rgba(0, 0, 0, 0.5326505602240896) 14%,
      rgba(255, 255, 255, 0) 40%
    ),
    linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 30%,
      rgba(0, 0, 0, 0.1292892156862745) 44%,
      rgba(255, 255, 255, 0) 64%
    ),
    url("/static/img/lx/section-3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  padding: 120px 110px 50px 110px;
  display: flex;
  flex-direction: column;

  @include to(480) {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0.4) 50%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("/static/img/lx/mobile/3.jpg");
  }

  @include to(768) {
    padding: 40px 20px;
  }

  &__img {
    @include to(768) {
      width: 125px;
      margin: 0 auto;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__bottom {
    margin-top: auto;
    height: 200px;

    @include to(768) {
      height: auto;
      margin-top: auto;
      transform: translateY(20px);
    }
  }

  &__benefits {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, auto);

    @include to(768) {
      grid-template-columns: repeat(2, auto);
      row-gap: 30px;
    }
  }

  &__benefit {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid rgba(#fff, 0.1);

    &:last-child {
      border-right: none;
    }

    @include to(768) {
      border-right: none;

      &:nth-child(1),
      &:nth-child(2) {
        .benefit__icon {
          display: none;
        }
      }
    }
  }
}

.benefit {
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    background: rgba(#fff, 0.1);
    border-radius: 50%;
    padding: 1px;

    @include to(768) {
      width: 40px;
      height: 40px;
    }
  }

  &__number {
    font-size: 80px;
    color: #fff;
    margin-bottom: 10px;

    @include to(768) {
      font-size: 40px;
    }
  }

  &__text {
    font-size: 14px;
    color: #7d8082;

    @include to(768) {
      width: 100px;
      text-align: center;
    }
  }
}
