.block-offer-container {
    // padding: 80px 0;
    width: 100%;
    @media (max-width: 1300px) {
        // padding: 0 0 80px;
    }
    @media (max-width: 800px) {
        // padding: 0 0 40px;
    }
}

.offer {
    width: 100%;
    position: relative;
    &:before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        height: 100%;
        width: 80%;
        background: url("../images/offer.jpg") no-repeat right;
        background-size: cover;
        @media (max-width: 800px) {
            width: 100%;
        }
    }
    &:after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgb(38, 38, 38);
        background: linear-gradient(90deg, rgba(38, 38, 38, 1) 0%, rgba(38, 38, 38, 0.98) 30%, rgba(0, 0, 0, 0) 100%);
    }
    &__content {
        position: relative;
        z-index: 1;
        // padding: 55px;
        @media (max-width: 600px) {
            // padding: 30px 20px;
        }
    }
    &__wrap-text {
        margin-bottom: 30px;
    }
    &__subtitle {
        color: #fff;
        font-size: 26px;
        text-transform: uppercase;
        // font-family: 'TacticSans';
        @media (max-width: 600px) {
            font-size: 16px;
        }
    }
    &__title {
        color: #fff;
        background: $primary;
        padding: 15px;
        font-size: 54px;
        // font-family: 'TacticSans-Bld';
        display: inline-block;
        margin: 20px 0;
        @media (max-width: 800px) {
            font-size: 6vw;
            margin: 10px 0;
        }
    }
    &__item {
        &__banner {
            position: relative;
            padding-bottom: 46.25%;
            height: 0;
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
        &__disclaimer {
            margin-top: 20px;
            p {
                font-size: 12px;
            }
        }
    }
}

@include to (1080) {
    div[id^=timer] {
        transform: scale(0.8) !important;
        transform-origin: 45% 0;
    }

    .timer {
        height: 120px;
    }
}
@include to (595) {
    div[id^=timer] {
        transform: scale(0.35) !important;
        transform-origin: 14% 0;
    }

    .timer {
        height: 50px;
    }
}