.title {
  font-size: 24px;
  margin-bottom: 45px;

  @include to(767) {
    font-size: 20px;
    margin-bottom: 35px;
  }

  &_like-h2 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 17px;
  }

  &_seo {
    font-size: 40px;
    line-height: 40px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 25px;

    @include to(767) {
      font-size: 22px;
      line-height: 22px;
    }
  }
}