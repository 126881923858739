.section-title {
    font-size: 60px;
    line-height: 60px;
    font-weight: 300;
    text-transform: uppercase;

    @include to(1660) {
        font-size: 40px;
        line-height: 40px;
    }
    @include to(800) {
        font-size: 30px;
        line-height: 30px;
    }

    &_theme {
        &_light {
            color: #fff;
        }
        &_dark {
            color: $c-very-dark;
        }
        &_more {
            font-size: 40px;
            line-height: 40px;

            @include to(800) {
                font-size: 18px;
                line-height: 20px;
            }
        }
    }
}