.section-vx {
    padding: 80px 0;

    @include to(1660) {
      padding: 60px 0 80px;
    }
    @include to(800) {
      padding: 20px 0 60px;
    }

    .model-vx {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        width: 100%;

        &__title {
            font-size: 60px;
            line-height: 60px;
            font-weight: 300;
            margin-bottom: 20px;
            text-transform: uppercase;
            text-align: center;
    
            @include to(800) {
              font-size: 30px;
              line-height: 30px;
              margin-bottom: 10px;
            }
        }

        &__img {
            width: 1100px;
            margin-bottom: 30px;

            @include to(1660) {
                width: 550px;
            }
            @include to(800) {
                width: 100%;
            }
        }

        .details-row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;

            @include to(800) {
                flex-wrap: wrap;
            }

            &__item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                width: 25%;

                @include to(800) {
                    width: calc(50% - 1px);
                }

                &:nth-child(-n+3) {
                    @include to(800) {
                        margin-bottom: 20px;
                    }
                }
            }

            &__icon {
                width: 60px;
                height: 60px;
                padding: 20px;
                border-radius: 50%;
                background: $c-gray-back;
                margin-bottom: 10px;

                @include to(800) {
                    width: 40px;
                    height: 40px;
                    padding: 12px;
                } 
            }

            &__num {
                font-size: 80px;
                line-height: 80px;
                font-weight: 300;
                margin-bottom: 10px;
                transition: all 0.5s;
      
                @include to(1660) {
                  font-size: 60px;
                  line-height: 60px;
                }
                @include to(800) {
                  font-size: 40px;
                  line-height: 40px;
                }
            }

            &__title {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                padding: 0 20px;
                text-align: center;
            }

            &__separator {
                width: 1px;
                height: 178px;
                background-color: $c-gray-divider;
                display: block;

                @include to(800) {
                    height: 115px;
                }

                &.mobile-hide {
                    @include to(800) {
                        display: none;
                    }
                }
            }

            .details-row__item:hover .details-row__num {
                color: $c-bronze-gold;
            }
        }
    }
}