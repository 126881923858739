.section-model {
  padding: 92px 0 112px;

  @include to(1660) {
    padding: 60px 0 80px;
  }
  @include to(800) {
    padding: 20px 0 60px;
  }

  .models {
    display: flex;
    justify-content: space-between;
    width: 100%;   
    overflow: hidden;

    @include to(1200) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__item {
      padding-top: 20px;
      &:not(:last-child) {
        position: relative;
        &:after {
          @include to(1200) {
            position: absolute;
            content: '';
            bottom: -60px;
            left: -20px;
            width: calc(100% + 40px);
            height: 1px;
            background: $c-gray-divider;
          }
        }

        @include to(1200) {
          margin-bottom: 100px;
        }
      }
    }

    .model {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      

      @include to(1200) {
        width: 100%;
      }

      &__title {
        font-size: 80px;
        line-height: 80px;
        font-weight: 300;
        margin-bottom: 20px;
        text-transform: uppercase;

        @include to(1660) {
          font-size: 60px;
          line-height: 60px;
        }
        @include to(800) {
          font-size: 40px;
          line-height: 40px;
          margin-bottom: 10px;
        }
      }
      &__subtitle {
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 1px;
        font-weight: 400px;
        text-transform: uppercase;
        margin-bottom: 20px;

        @include to(1660) {
          font-size: 16px;
          line-height: 16px;
        }
        @include to(800) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      &__img {
        width: 770px;
        margin-bottom: 20px;

        @include to(1660) {
          width: 550px;
        }
        @include to(800) {
          width: 100%;
        }
      }

      .details-block {
        width: 100%;

        &__row {
          display: flex;
          justify-content: space-between;

          &_level_first {
            margin-bottom: 40px;

            @include to(800) {
              margin-bottom: 20px;
            }
          }
        }
        &__item {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          cursor: pointer;
        }

        &__separator {
          width: 1px;
          height: 138px;
          background-color: $c-gray-divider;
          display: block;

          @include to(1660) {
            height: 115px;
          }
        }

        &__icon {
          width: 20px;
          margin-bottom: 10px;
        }

        &__num {
          font-size: 80px;
          line-height: 80px;
          font-weight: 300;
          margin-bottom: 10px;

          @include to(1660) {
            font-size: 60px;
            line-height: 60px;
          }
          @include to(800) {
            font-size: 40px;
            line-height: 40px;
          }
        }

        &__title {
          font-size: 18px;
          line-height: 18px;
          font-weight: 400;
          padding: 0 10px;

          @include to(1660) {
            font-size: 14px;
            line-height: 14px;
          }
          @include to(800) {
            line-height: 18px;
          }
        }
        .details-block__item:hover .details-block__num {
          color: $c-bronze-gold;
        }
      }
    }
  }
}