 .checkbox-container {
   cursor: pointer;
   margin: 0;
   display: flex;
   white-space: nowrap;
   align-items: center;
 }

 .checkbox-itself {
   display: block;
   float: left;
   flex-shrink: 0;
   height: 16px;
   width: 16px;

   // &.checked {
   //     background: url("../images/checkboxes/suz_agree.png") no-repeat center;
   //     background-size: contain;
   // }
   &.checked {
     background: url("../images/icons/check.svg") no-repeat center;
     background-size: contain;
   }

   &:not(.checked) {
     background: url("../images/checkboxes/suz_disagree.png") no-repeat center;
     background-size: contain;
   }

   &.inverted.checked {
     background: url("../images/checkboxes/suz_disagree.png") no-repeat center;
     background-size: contain;
   }

   &.inverted:not(.checked) {
     background: url("../images/checkboxes/suz_agree.png") no-repeat center;
     background-size: contain;
   }
 }

 .checkbox-itself+* {
   margin-left: 10px;
 }

 .checkmark {
   position: relative;
   padding-left: 30px;
   margin-bottom: 10px;
   user-select: none;
   cursor: pointer;

   &:last-child {
     margin-bottom: 0;
   }

   input[type="checkbox"],
   input[type="radio"] {
     position: absolute;
     top: 0;
     left: 0;
     visibility: hidden;
   }

   label {
     cursor: pointer;
     font-size: 14px;
   }

   span {
     display: inline-block;
   }

   &__box {
     position: absolute;
     top: 50%;
     left: 0;
     transform: translateY(-50%);
     width: 20px;
     height: 20px;
     border: 1px solid #8294A6;

     &::before {
       position: absolute;
       content: '';
       width: 18px;
       height: 15px;
       top: 20%;
       left: 50%;
       transform: translate(-50%, -50%);
       opacity: 0;
       background: url('/static/images/icons/check.svg') center no-repeat;
       background-size: contain;
       transition: .25s ease-out;
     }
   }

   input[type="checkbox"]:checked~.checkmark__box,
   input[type="radio"]:checked~.checkmark__box,
   &.selected .checkmark__box {
     &::before {
       opacity: 1;
       top: 45%;
     }
   }
 }