.section-info {
  max-width: 540px;
  margin-left: auto;

  @include to(768) {
    width: 100%;
    margin: 0 auto !important;
  }

  h2 {
    color: #fff;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 60px;
  }

  p {
    line-height: 32px;
    margin-bottom: 40px;
    color: #fff;
    font-size: 18px;
  }

  .btn {
    background-color: #fff;

    &__original {
      background-color: transparent;
      color: #fff;
    }

    &__icon {
      width: 18px;
      height: 18px;
    }

    &__icon-arrow {
      width: 10px;
      height: 18px;
    }

    &:hover .btn__icon {
      transform: translateX(0);
    }
  }
}

.section-menu {
  &__progress {
    position: relative;
    height: 390px;
    width: 1px;
    background: #c4c4c4;
    margin-right: 40px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      height: 40px;
      border-radius: 20px;
      background: $c-bronze-gold;
    }
  }

  &__menu {
    display: flex;

    @include to(768) {
      display: none;
    }

    ul {
      padding: 10px 0;
    }

    li {
      font-size: 14px;
      color: #fff;
      opacity: 0.7;
      margin-bottom: 31px;
      list-style: none;
      text-transform: uppercase;
      letter-spacing: 2px;

      &:first-child {
        font-size: 20px;
        opacity: 1;
      }

      a {
        cursor: pointer;
        color: #fff;
      }
    }
  }
}

.lx-popup {
  transform: translateX(0);
  transition: 0.5s;
}

.close-lx-popup {
  transform: translateX(100%);
  transition: 0.5s;
}

body.disable-scroll {
  overflow: hidden;
}

.lx-popup__cards-desktop {
  display: block !important;

  @include to(768) {
    display: none !important;
  }
}

.lx-popup__cards-mobile {
  display: none !important;
  @include to(769) {
    display: block !important;
  }
}
