.stock {
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin: -8px;
    background: $c-gray-back;
  }

  &__nothing {
    margin: 20px 10px;
  }

  &__overflow {
    display: none;
    z-index: 9;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(#000, .1);

    &.show {
      display: block;
    }
  }
}