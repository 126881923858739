.section-double {
    width: 100%;
    display: flex;

    @include to(1200) {
        flex-direction: column;    
    }

    &__content {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 140px;

        &:first-child {
            background: $c-gray-back;
        }

        @include to(1660) {
            padding: 85px;
        }
        @include to(1200) {
           width: 100%;  
           padding: 40px;
        }
        @include to(800) {
            padding: 40px 20px;
         }
    }

    &__title {
        text-align: center;
        margin-bottom: 30px;
        
        @include to(800) {
            margin-bottom: 0;
        }
    }

    &__text {
        text-align: center;
        color: $c-dark-gray;
        font-size: 18px;

        @include to(800) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}