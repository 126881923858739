.section-banner {
  // padding-top: 80px;
  position: relative;

  @include to(1660) {
    // padding-top: 60px;
  }

  &_credit {
    img {
      width: 100%;
      height: auto;
    }
  }

  &_tablet {
    display: none;

    @include to(1400) {
      display: block;
    }
  }

  .main-screen {
    width: 100%;
    height: calc(100vh - 80px);
    position: relative;
    display: block;

    &__video {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include to(1200) {
        position: absolute;
        top: 0;
        left: 0;
      }

      @include to(800) {
        display: none;
      }
    }

    @include to(1660) {
      height: calc(100vh - 60px);
    }

    @include to(1200) {
      height: 0;
      padding-top: 52%;
    }

    @include to(800) {
      background: url(../img/poster.jpg) center no-repeat;
      background-size: cover;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #000;
        transform: translate(-50%, -50%);
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        border: 13px solid transparent;
        border-left: 24px solid #fff;
        transform: translate(-10px, -50%);
      }
    }

    &_page {
      &_txl {
        @include to(800) {
          background: url(../img/poster_txl.jpg) center no-repeat;
          background-size: cover;
        }
      }

      &_vx {
        @include to(800) {
          background: url(../img/poster_vx.jpg) center no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .top-banner {
    width: 100%;
    background: url(../img/1920х1000.jpg) center no-repeat;
    background-position: top;
    background-size: cover;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // &:before {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     bottom: 0;
    //     background: rgba( 0, 0, 0, .38);
    // }

    &_page {
      &_txl {
        background: url(../img/banner-txl.jpg) center no-repeat;
        background-size: cover;
      }

      &_vx {
        background: url(../img/banner-vx.jpg) center no-repeat;
        background-size: cover;
      }
    }

    @include to(1660) {
      height: calc(100vh - 60px);
    }

    @include to(1200) {
      // height: calc(100vh - 100px);
      padding-top: 52%;
      height: auto;
    }

    @include to(800) {
      padding-top: 52%;
      height: auto;
    }

    .title-main {
      color: $c-very-dark;
      text-align: center;
      position: absolute;
      top: 107px;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      width: 100%;
      padding: 0 20px;

      @include to(1660) {
        top: 130px;
      }

      @include to(1200) {
        top: 5%;
      }

      &__small {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 2px;
        font-weight: 400;
        display: block;
        margin-bottom: 10px;

        @include to(1660) {
          font-size: 20px;
          line-height: 24px;
        }

        @include to(1200) {
          font-size: 16px;
          line-height: 20px;
        }

        @include to(600) {
          font-size: 10px;
          line-height: 12px;
          margin-bottom: 0;
        }
      }

      &__big {
        font-size: 69px;
        line-height: 80px;
        font-weight: 300;

        @include to(1660) {
          font-size: 55px;
          line-height: 60px;
        }

        @include to(1200) {
          font-size: 38px;
          line-height: 40px;
        }

        @include to(600) {
          font-size: 25px;
          line-height: 30px;
        }
      }

      &_theme {
        &_light {
          color: #fff;
          top: 40px;
        }
      }
    }

    &-btn--container {
      display: flex;
      position: absolute;
      top: 35%;
      width: 100%;
      right: 40px;

      justify-content: flex-end;

      @include to(1000) {
        // top: auto;
        bottom: 40px;
      }

      @include to(550) {
        justify-content: center;
        right: auto;
        top: auto;
      }
    }

    &--sign-guarantee {
      position: absolute;
      color: #fff;
      top: 12%;
      right: 40px;
      width: 200px;
      border: 1px solid #fff;
      border-radius: 0 0 0 25%;
      display: flex;
      flex-flow: row;
      align-items: center;

      @include to(870) {
        display: none;
      }

      &--year {
        font-size: 82px;
        padding: 0 5px;
      }

      &--description {
        text-transform: uppercase;
        padding: 0 5px;
      }
    }
  }
  .top-banner_txl_new_main {
    width: 100%;
    background: url(../img/banner-txl-2-0.jpg) center no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // &:before {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     bottom: 0;
    //     background: rgba( 0, 0, 0, .38);
    // }

    &_page {
      &_txl {
        background: url(../img/banner-txl.jpg) center no-repeat;
        background-size: cover;
      }

      &_vx {
        background: url(../img/banner-vx.jpg) center no-repeat;
        background-size: cover;
      }
    }

    @include to(1660) {
      height: calc(100vh - 60px);
    }

    @include to(1200) {
      // height: calc(100vh - 100px);
      padding-top: 52%;
      height: auto;
    }

    @include to(800) {
      padding-top: 52%;
      height: auto;
    }

    .title-main {
      color: $c-very-dark;
      text-align: center;
      position: absolute;
      top: 107px;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      width: 100%;
      padding: 0 20px;

      @include to(1660) {
        top: 130px;
      }

      @include to(1200) {
        top: 5%;
      }

      &__small {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 2px;
        font-weight: 400;
        display: block;
        margin-bottom: 10px;

        @include to(1660) {
          font-size: 20px;
          line-height: 24px;
        }

        @include to(1200) {
          font-size: 16px;
          line-height: 20px;
        }

        @include to(600) {
          font-size: 10px;
          line-height: 12px;
          margin-bottom: 0;
        }
      }

      &__big {
        font-size: 69px;
        line-height: 80px;
        font-weight: 300;

        @include to(1660) {
          font-size: 55px;
          line-height: 60px;
        }

        @include to(1200) {
          font-size: 38px;
          line-height: 40px;
        }

        @include to(600) {
          font-size: 25px;
          line-height: 30px;
        }
      }

      &_theme {
        &_light {
          color: #fff;
          top: 40px;
        }
      }
    }

    &-btn--container {
      display: flex;
      position: absolute;
      top: 35%;
      width: 100%;
      right: 40px;

      justify-content: flex-end;

      @include to(1000) {
        // top: auto;
        bottom: 40px;
      }

      @include to(550) {
        justify-content: center;
        right: auto;
        top: auto;
      }
    }

    &--sign-guarantee {
      position: absolute;
      color: #fff;
      top: 12%;
      right: 40px;
      width: 200px;
      border: 1px solid #fff;
      border-radius: 0 0 0 25%;
      display: flex;
      flex-flow: row;
      align-items: center;

      @include to(870) {
        display: none;
      }

      &--year {
        font-size: 82px;
        padding: 0 5px;
      }

      &--description {
        text-transform: uppercase;
        padding: 0 5px;
      }
    }
  }

  .top-banner-2 {
    width: 100%;
    background: url(../img/exeed-vx-march.jpg) center no-repeat;
    background-size: cover;
    height: calc(100vh - 80px);
    position: relative;

    @include to(1660) {
      height: calc(100vh - 60px);
    }

    @include to(1200) {
      // height: calc(100vh - 100px);
      padding-top: 52%;
      height: auto;
    }

    @include to(800) {
      padding-top: 52%;
      height: auto;
    }

    .title-main {
      color: $c-very-dark;
      text-align: center;
      position: absolute;
      top: 107px;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      width: 100%;
      padding: 0 20px;

      @include to(1660) {
        top: 130px;
      }

      @include to(1200) {
        top: 5%;
      }

      &__small {
        font-size: 30px;
        line-height: 30px;
        letter-spacing: 2px;
        font-weight: 400;
        display: block;
        margin-bottom: 10px;

        @include to(1660) {
          font-size: 24px;
          line-height: 24px;
        }

        @include to(1200) {
          font-size: 18px;
          line-height: 20px;
        }

        @include to(600) {
          font-size: 12px;
          line-height: 12px;
          margin-bottom: 0;
        }
      }

      &__big {
        font-size: 80px;
        line-height: 80px;
        font-weight: 300;
        margin-top: 20px;

        @include to(1660) {
          font-size: 60px;
          line-height: 60px;
        }

        @include to(1200) {
          font-size: 40px;
          line-height: 40px;
        }

        @include to(600) {
          font-size: 26px;
          line-height: 26px;
        }
      }

      &_theme {
        &_light {
          color: #fff;
          top: 40px;
        }
      }
    }

    &-btn--container {
      display: flex;
      position: absolute;
      top: 280px;
      width: 100%;
      justify-content: center;

      @include to(1000) {
        top: auto;
        bottom: 40px;
      }

      @include to(550) {
        bottom: auto;
        top: 250px
      }
    }

    &--sign-guarantee {
      position: absolute;
      color: #fff;
      top: 40px;
      right: 40px;
      width: 200px;
      border: 1px solid #fff;
      border-radius: 0 0 0 25%;
      display: flex;
      flex-flow: row;
      align-items: center;

      @include to(870) {
        display: none;
      }

      &--year {
        font-size: 82px;
        padding: 0 5px;
      }

      &--description {
        text-transform: uppercase;
        padding: 0 5px;
      }
    }
  }

  @include from(551) {
    .top-banner-mobile {
      display: none;
    }
  }

  @include to(550) {
    .top-banner-mobile {
      position: relative;
      justify-content: center;
      display: flex;
      align-items: center;


      .title-main-mobile {
        position: absolute;
        top: 42px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        width: 100%;

        .title-main__small {
          display: block;
          font-weight: normal;
          font-size: 16px;
          line-height: 16px;
        }

        .title-main__big {
          display: block;
          text-transform: uppercase;
          font-style: normal;
          font-weight: 300;
          font-size: 1.2rem;
          line-height: 40px;
        }
      }

      &-img {
        width: 100%;
      }
    }
  }

  &__button {
    position: absolute !important;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);

    @include to(800) {
      bottom: 10px;
      background: rgba(255, 255, 255, 0.3) !important;
    }
  }

  .scroll-down {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    background: $c-dark-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include to(1660) {
      width: 40px;
      height: 40px;
    }

    @include to(800) {
      // display: none;
    }

    &__img {
      width: 13px;
    }

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 20%;
      left: 50%;
      height: 8px;
      width: 8px;
      border: 2px solid $c-bronze-gold;
      transform: translate(-50%, -100%) rotate(45deg);
      border-top: transparent;
      border-left: transparent;
      animation: scrollDown 1.5s ease infinite;
    }

    &:after {
      top: 40%;
    }

    @keyframes scrollDown {
      0% {
        opacity: 0;
      }

      30% {
        opacity: 1;
      }

      60% {
        opacity: 1;
      }

      100% {
        opacity: 0;
        top: 80%;
      }
    }
  }
}