.text-img {
    position: relative;
    height: calc(100vh - 60px);
    overflow: hidden;
    text-align: center;
    background: #000;
    @include from(1025) {
        text-align: left
    }
    @include from (1450) {
        height: calc(100vh - 80px)
    }
    &__content {
        position: relative;
        color: #fff
    }
    &__title {
        font-size: 30px;
        line-height: 1;
        font-weight: 300;
        text-transform: uppercase;
        @include from(1025) {
            font-size: 40px
        }
        @include from (1450) {
            font-size: 60px
        }
    }
    &__text {
        line-height: 1.7;
        color: #cacaca;
        margin-top: 30px;
        @include from(1025) {
            margin-top: 40px
        }
    }
    &__button {
        margin-top: 30px;
        @include from(1025) {
            margin-top: 40px
        }
    }
    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &:before {
            display: none
        }
        &_gradient:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #181b1d;
            opacity: .8;
            @include from(1025) {
                width: 920px;
                background: -webkit-gradient(linear, left top, right top, from(#181b1d), to(rgba(24, 27, 29, 0)));
                background: linear-gradient(90deg, #181b1d 0, rgba(24, 27, 29, 0) 100%)
            }
        }
    }
}