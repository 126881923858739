.conditional__discounts {
    display: flex;
    align-items: center;
    &__btn {
        &> span {
            margin-right: 9px!important;
        }
        @include to(1441) {
            height: 30px!important;
            min-height: 30px!important;
            padding: (5px 23px)!important;
        }
    }
    &__countainer {
        width: 100%;
    }
    &__has__checkbox::before {
        content: ' ';
        background-image: url('/static/img/checkbox.jpg');
        width: 16px;
        height: 16px;
        position: absolute;
    }
    &__item {
        border-left: 22px transparent solid;
        border-bottom: 1px #EBE8E8 solid;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 5px 0;
        @include from(1001) {
            flex-flow: column;
            flex-wrap: wrap;
        }
         @include from(1920) {
            flex-flow: row;
            flex-wrap: nowrap;
        }
        &__no__bottomborder {
            border-bottom: 1px transparent solid;
            margin-bottom: 35px;
            @include to(1100) {
                margin-bottom: 5px;
            }
              p {
                color: #cdcdcd!important;
              }
        }
        p {
        @include to(1440) {
             font-size: 8px;
        }
         @include from(1920) {
             font-size: 12px;
        }
            color: black;
            font-size: 11px;
            font-weight: 500;
        }
    }
    &__total {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-flow: column;
    }
}