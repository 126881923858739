.filter {
  width: 300px;
  min-width: 300px;
  margin-right: 35px;

  @include to(1024) {
    width: 250px;
    min-width: 250px;
  }

  @include to(767) {
    z-index: 10;
    position: fixed;
    top: 60px;
    left: 0;
    width: 350px;
    max-width: 100%;
    height: calc(100% - 60px);
    padding: 10px 20px;
    transform: translateX(-100%);
    transition: transform .3s ease-in-out;
    border-right: 1px solid rgb(179, 179, 179);
    background-color: #fff;
    overflow-y: auto;
  }

  &.open {
    transform: translateX(0);
  }

  &-inner {
    position: sticky;
    top: 100px;

    @include to(1660) {
      top: 80px;
    }

    @include to(767) {
      position: static;
    }
  }

  &__btn-apply {
    display: none;

    @include to(767) {
      display: flex;
      margin-bottom: 10px;
    }
  }

  &__btn {
    display: none;
    height: 35px;
    min-height: 35px;
    margin-bottom: 15px;
    margin-right: auto;

    span {
      margin-right: 0;
    }

    @include to(767) {
      display: block;
    }
  }

  &__main-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__main-title {
    font-size: 24px;
    font-weight: 700;

    @include to(767) {
      font-size: 20px;
    }
  }

  &__close {
    display: none;
    cursor: pointer;

    @include to(767) {
      display: block;
    }
  }

  &__category {
    padding: 10px;
    margin: 10px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #EBEBEB;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    span {
      font-size: 16px;
    }

    img {
      width: 18px;
      height: 20px;
    }
  }

  &__content {
    margin-top: 30px;
  }

  &__content-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__content-item {
    margin-bottom: 12px;
  }

  &__color {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    font-size: 0;
    margin-right: 12px;
    cursor: pointer;

    span {
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid #b3b3b3;
      border-radius: 50%;
      transition: background-image .2s ease-in-out;
    }

    input:checked + span {
      background-image: url('../images/icons/check.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}