.section-supply {
    display: flex;
    width: 100%;
    position: relative;
    @include to(1300) {
        flex-direction: column;
    }
    &__img-bl {
        width: 50%;
        padding-top: 40%;
        background: url(../img/imageLX.jpg) center no-repeat;
        background-size: cover;
        @include to(1300) {
            width: 100%;
            padding-top: 100%;
        }
        // @include to(800) {
        //     background: url(../img/mobile/imageLX.jpg) center no-repeat;
        //     background-size: cover;
        // }
    }
    &-wrapper {
        display: flex;
        @include to(1400) {
            flex-direction: column;
        }
        &_reverse {
            @include to(1400) {
                flex-direction: column-reverse;
            }
        }
    }
    &__img-bl-new {
        width: 51%;
        background: url('../img/credit/imageLX-new.jpg') center no-repeat;
        background-size: cover;
        background-position: 50% 0;
        @include to(1400) {
            width: 100%;
            padding-top: 53%;
            background: url('../img/credit/imageLX-new-tablet.jpg') center no-repeat;
            background-size: cover;
        }
    }
    &__img-tlx-new {
        width: 54.9%;
        background: url('../img/credit/imageTXL-new.jpg') center no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        @include to(1400) {
            width: 100%;
            padding-top: 57%;
            background: url('../img/credit/imageTXL-new-tablet.jpg') center no-repeat;
            background-size: cover;
        }
    }
    &__img-vx-new {
        width: 51%;
        background: url('../img/credit/imageVX-new.jpg') center no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        @include to(1400) {
            width: 100%;
            padding-top: 57%;
            background: url('../img/credit/imageVX-new-tablet.jpg') center no-repeat;
            background-size: cover;
        }
    }
    &__img {
        width: 100%;
    }
    .about-supply {
        width: 25%;
        background: $c-gray-back;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 40px;
        // min-height: 442px;
        @include to(1300) {
            width: 100%;
        }
        @include to(800) {
            padding: 40px 20px;
        }
        &__subtitle {
            padding: 7px 15px;
            color: $c-bronze-gold;
            font-weight: 400;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 1px;
            margin-bottom: 20px;
            background-color: rgba(199, 142, 102, 0.1);
            @include to(1660) {
                font-size: 16px;
                line-height: 16px;
            }
            @include to(800) {
                font-size: 12px;
                line-height: 15px;
                margin-bottom: 10px;
            }
        }
        &__title {
            color: #30363A;
            margin-bottom: 20px;
        }
        &__price {
            color: #7D8082;
            letter-spacing: 2px;
            text-transform: uppercase;

            color: #c78e66;
            margin-bottom: 20px;
            @include from(1301) {
                margin-bottom: 65px;
            }          
          @include to(414) {
            font-size: 20px;
          }
          @include from(415) {
            font-size: 29px;
          }

          span {
            font-weight: 600;
          }
        }
        &__desc {
            font-size: 18px;
            line-height: 32px;
            font-weight: 400;
            color: $c-gray-light;
            margin-bottom: 40px;
            @include to(1660) {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 30px;
            }
            @include to(800) {
                margin-bottom: 25px;
            }
        }
    }
    .benefits {
        width: 100%;
        &__item {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid $c-gray-divider;
            width: 100%;
            justify-content: space-between;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        &__titlewrap {
            display: flex;
        }
        &__count {
            color: $c-very-dark;
            font-weight: 600;
            font-size: 14px;
        }
        &__icon {
            height: 20px;
            width: 20px;
            margin-right: 23px;
            @include to(800) {
                margin-right: 15px;
            }
        }
        &__title {
            font-size: 18px;
            line-height: 18px;
            color: $c-gray-medium;
            font-weight: normal;
            transition: all 0.5s;
            @include to(1660) {
                font-size: 14px;
                line-height: 18px;
            }
        }
        .benefits__item:hover .benefits__title {
            color: $c-bronze-gold;
        }
        &-container {
            display: flex
        }
        &-arrows {
            display: flex;
            flex-direction: column;
            @include from(1301) {
                margin-bottom: 80px;
            }
            @include to(1300) {
                flex-direction: row;
                flex-wrap: wrap;
            }
            &-list {
                display: flex;
                align-items: center;
                width: 25%;
                background: #F2F2F2;
                
                @include to(1300) {
                    width: 100%;
                    padding-right: 40px;
                    padding-left: 40px;
                    padding-bottom: 20px;
                }
                @include to(800) {
                    padding-right: 20px;
                    padding-left: 20px;
                }
                @include from(1280) {
                    // padding-right: 40px;
                    justify-content: flex-end;
                }
            }
            &-item-wrapper {
                display: flex;
                flex-direction: row;
                margin-bottom: 15px;
                @include to(1300) {
                    width: calc(50% - 10px);
                }
                &:nth-child(odd) {
                    @include to(1300) {
                        margin-right: 20px;
                    }
                }
                &:nth-child(even) {
                    @include to(1300) {
                        .benefits-arrows-item {
                            .benefits-arrows-item-inner {
                                padding-right: 0;
                                padding-left: 20px;
                                @include to(600) {
                                    padding-left: 10px;
                                }
                                @include to(400) {
                                    padding-right: 10px;
                                }
                            }
                            &::before {
                                display: none;
                            }
                            &::after {
                                content: '';
                                z-index: 1;
                                left: 0px;
                                bottom: 0px;
                                border: 2rem solid transparent;
                                border-top: 2rem solid #F2F2F2;
                                transform: rotate(90deg);
                                background-color: #fff;
                                @include to(400) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            &-item {
                display: flex;
                height: 4rem;
                width: 100%;
                &::before {
                    content: '';
                    z-index: 1;
                    left: 0px;
                    bottom: 0px;
                    border: 2rem solid transparent;
                    border-top: 2rem solid #F2F2F2;
                    transform: rotate(-90deg);
                    background-color: #fff;
                    @include to(400) {
                        display: none;
                    }
                }
            }
            &-item-inner {
                display: flex;
                justify-content: space-between;
                width: calc(100% - 4rem);
                padding-right: 20px;
                background-color: #fff;
                @include to(600) {
                    padding-right: 10px;
                }
                @include to(400) {
                    width: 100%;
                    padding-left: 10px;
                }
            }
            &-item__icon {
                width: 35px;
                height: 35px;
                margin-top: auto;
                margin-right: 10px;
                margin-bottom: auto;
                background-color: #fff;
                @include to(600) {
                    display: none;
                }
            }
            &-item__text {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                color: #C78E66;
                flex-grow: 1;
                @include to(600) {
                    font-size: 12px;
                }
            }
            &-item__arrow {
                width: 8px;
                height: auto;
                margin-left: 10px;
                @include to(600) {
                    width: 6px;
                }
            }
        }
    }
    .buttons {
        &__wrap {
            width: 100%;
            margin-top: 50px;
            // display: grid;
            // grid-template-columns: repeat(2, auto);
            // column-gap: 10px;
            @include from (1920) {
                display: block;
                @apply space-y-4;
            }
            @include to(1500) {
                display: block;
            }
            @include to(1300) {
                display: flex;
                width: 100%;
            }
            @include to(500) {
                display: block;
            }
            span {
                font-size: 11px;
            }
            button {
                margin-top: 0;
            }
            .btn {
                padding-left: 0 !important;
                padding-right: 0 !important;
                margn-right: 8px;

                &:first-child {
                    margin-bottom: 10px;

                    @include to(1500) {
                        margin-bottom: 10px;
                    }
                    @include to(1300) {
                        margin-bottom: 0;
                        margin-right: 10px;
                    }
                    @include to(500) {
                        margin-bottom: 10px;
                        margin-right: 0;
                    }
                }
                @include to(1300) {
                    width: 100%;
                }
                @include to(500) {
                    width: 100%;
                }
            }
            .btn__text {
                font-size: 10px;
            }
        }
    }
    @include to(1300) {
        .buttons__wrap {
            grid-template-columns: 1fr;
            row-gap: 15px;
        }
        .about-supply {
            align-items: flex-end;
        }
    }
}