.section {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;

  @include to(767) {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  &_first {
    width: 100%;
    padding-bottom: 30px;

    @include to(767) {
      padding-bottom: 20px;
    }
  }

  &_bg {
    padding: 50px 30px;

    @include to(767) {
      padding: 30px 15px;
    }
  }
}