.pagination {
  &-wrapper {
    display: flex;
    justify-content: center;
    margin: 10px 0 10px;

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border: 1px solid #30363A;
      margin: 0 5px;

      &.active {
        color: #C78E66;
        border-color: #C78E66;
      }
    }
  }
}