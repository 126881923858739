.image {
  &_offer {
    overflow: hidden;
    position: relative;
    padding-top: 25.7%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      font-weight: 700;
      font-size: 12px;
      transform: translate(-50%, -50%);
    }
  }
}