.second-section {
  position: relative;
  background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9051995798319328) 0%,
      rgba(0, 0, 0, 0.5326505602240896) 14%,
      rgba(255, 255, 255, 0) 40%
    ),
    linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5130427170868348) 0%,
      rgba(0, 0, 0, 0.1292892156862745) 34%,
      rgba(255, 255, 255, 0) 54%
    ),
    url("/static/img/lx/section-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  padding: 100px;
  display: flex;

  @include to(480) {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9051995798319328) 0%,
        rgba(255, 255, 255, 0) 50%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.9051995798319328) 0%, rgba(255, 255, 255, 0) 60%),
      url("/static/img/lx/mobile/2.jpg");
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center;
  }

  @include to(768) {
    padding: 90px 20px 50px 20px;
    background-size: auto 100vh;
  }

  &__text {
    line-height: 32px;
    max-width: 540px;

    img {
      margin-bottom: 40px;

      @include to(768) {
        width: 215px;
        height: 44px;
      }
    }

    p {
      font-size: 18px;
      color: #fff;

      @include to(768) {
        display: none;
      }
    }
  }

  &__car-info {
    width: 828px;
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);

    @include to(768) {
      width: 90%;
      bottom: 70px;
    }
  }

  &__car-name {
    font-size: 70px;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 60px;

    @include to(768) {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }

  &__car-time {
    font-size: 35px;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;

    @include to(768) {
      font-size: 16px;
    }
  }

  &__line {
    display: none;

    @include to(768) {
      display: block;
      width: 105px;
      height: 1px;
      background-color: $c-bronze-gold;
      margin: 0 auto 20px auto;
    }
  }

  &__play {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    @include to(768) {
      width: 60px;
      height: 60px;
    }
  }

  &__arrow-down {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $c-dark-gray;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include to(768) {
      display: none;
    }

    img {
      transform: rotate(90deg);
    }
  }

  &__arrow-down-mobile {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $c-dark-gray;
    padding: 17px 20px;
    display: none;
    align-items: center;
    justify-content: space-between;

    @include to(768) {
      display: flex;
    }

    span {
      color: #fff;
      text-transform: uppercase;
    }

    img {
      transform: rotate(90deg);
    }
  }
}
