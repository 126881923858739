.web_block_text__content ul {
    list-style: none;
    padding-left: 25px;
    margin-bottom: 32px;
  }
  .web_block_text__content ul li {
    position: relative;
  }
  .web_block_text__content ul li:before {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: -25px;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #c78e66;
    border-right: 2px solid #c78e66;
    transform: rotate(-45deg);
  }
  .web_block_text__content ol {
    list-style: none;
    padding-left: 25px;
    margin-bottom: 0;
    counter-reset: counter-numbers;
  }
  .web_block_text__content ol li {
    counter-increment: counter-numbers;
    position: relative;
  }
  .web_block_text__content ol li:before {
    position: absolute;
    left: -25px;
    width: 6px;
    height: 6px;
    content: counter(counter-numbers) ".";
    color: #c78e66;
  }
  