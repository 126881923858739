.btn {
  position: relative;
  padding: 5px 30px;
  height: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  cursor: pointer;
  transition: 0.5s;
  background: transparent;

  @include to(400) {
    padding: 5px 20px;
  }

  &_border {
    &_light {
      border: 1px solid #fff;
    }

    &_dark {
      border: 1px solid $c-dark-gray;
    }
  }

  &_theme {
    &_full {
      background: #fff;
      border: none;
    }

    &_dark {
      background: $c-dark-gray;
      border: none;
      // order: 1;
      // @include to(1100) {
      // }
    }

    &_brown {
      background: rgba(199, 142, 102, 0.2);
      bottom: none;
    }
  }

  &__text {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 2px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    margin-right: 20px;
    transition: all 0.5s;
    white-space: nowrap;

    &_theme {
      &_dark {
        color: #fff;
      }

      &_light {
        color: $c-txt;
      }

      &_brown {
        color: #C78E66;
      }
    }

    @include to(1660) {
      font-size: 9px;
    }
  }

  &__icon {
    width: 10px;
    transition: all 0.5s;

    @include to(1660) {
      width: 8px;
    }
  }

  &_full {
    width: 100%;
  }

  &:hover .btn__text {
    color: $c-bronze-gold;
  }

  &:focus .btn__text,
  &:active .btn__text {
    color: $c-gray-medium;
  }

  &:hover .btn__icon {
    transform: translateX(3px);
  }

  &-test-drive {
    display: block;
    width: 100%;
    max-width: 400px;
    color: #fff;
    margin: auto auto 20px;
    border: 1px solid #c78e66;
    text-transform: uppercase;
    white-space: nowrap;

    @include from(1450) {
      font-size: 9px;
      padding: 11px 20px;
    }

    @include from(1025) {
      width: auto;
      padding-right: 20px;
      padding-left: 20px;
      margin: 0 0 0 32px;
    }

    &-header {
      margin-top: 0;
    }

    @include to(640) {
      &-block {
        margin: 0 auto;
      }
    }
  }

  &:disabled,
  button[disabled] {
    background-color: $primary;
    opacity: .25;
  }
}

.pulse {
  background-color: rgba($c-bronze-gold, 0.17);
  border-radius: 50%;
  animation: pulse 0.7s ease-out;
  transform: scale(0);
  position: absolute;
}

@keyframes pulse {
  to {
    transform: scale(2);
    background-color: ($c-bronze-gold, 0.25);
    opacity: 0;
  }
}

.button {
  display: inline-block;
  font: 9px/18px Montserrat, sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: 11px 20px;
  -webkit-transition: background 0.1s, color 0.1s, border 0.1s;
  transition: background 0.1s, color 0.1s, border 0.1s;

  &_border {
    color: #fff;
    border: 1px solid #fff;
  }

  &_arrow {
    position: relative;
    padding-right: 45px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: calc(50% - 5px);
      right: 22px;
      width: 10px;
      height: 10px;
      border-bottom: 1px solid #c78e66;
      border-right: 1px solid #c78e66;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transition: border 0.1s;
      transition: border 0.1s;
    }

    @include from(1450) {
      padding-right: 60px;

      &:after {
        top: calc(50% - 6px);
        right: 33px;
        width: 13px;
        height: 13px;
      }
    }
  }
}