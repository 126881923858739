
.mobile {
	.agreement {
		width: 240px;
	}
	.horizontal {
		.input-block {
			flex-direction: column;
		}
		.input {
			height: 43px;
			margin-right: 0;
			margin-bottom: 10px;
		}
	}

	.callback-request-container input {
		width: calc(100% - 60px);
	}
}

.callback-form {
	display: flex;
	flex-direction: column;

	&.horizontal {
		align-items: flex-start;
	}

	&:not(.horizontal) {
		.agreement {
			width: 275px;
		}
	}
}

.inverted {
	&.agreement-text {
		color: $default-text;
	}
}

.horizontal {
	.input-block {
		flex-direction: row;
		align-items: stretch;
		max-width: initial;
	}

	.input {
		margin-bottom: 0;
		margin-right: 10px;
		height: auto;
	}

	.agreement {
		margin-top: 10px;
	}

	.dropdown {
		margin: 10px auto 0;
	}
}

.contacts {
	align-items: center;
	.agreement:not(.horizontal) {
		width: auto;
	}
}

.form-text {
	color: #fff;
	font-size: 16px;
	margin-bottom: 15px;
	// font-family: 'TacticSans-Light';
}

.input-block {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
}

.input {
	width: 345px;
	height: 48px;
	padding: 5px 15px;
	border: 1px solid $grey;
	// font-family: 'TacticSans-Light';
	background: #e6ebf1;
	margin-right: 10px;
	display: flex;
	align-items: center;
	font-size: 14px;

	@media (max-width: 800px) {
		font-size: 10px;
	}
}

.red-btn {
	width: 48px;
	height: 48px;
	outline: none;
}

.btn-primary {
	width: 48px;
	height: 48px;
	background: $primary;
	position: relative;
	cursor: pointer;
	&:after {
		position: absolute;
		content: '';
		top: 50%;
		transform: translate(-50%, -50%);
		left: 50%;
		width: 12px;
		height: 12px;
		background: url("../images/icons/arrow-btn.svg") no-repeat center;
	}
	&:disabled,
    button[disabled] {
        background-color: $primary;
				opacity: .25
    }
}

.agreement {
	display: flex;
	justify-content: flex-start;
	position: relative;
	align-items: center;
	margin-top: 10px;
	font-size: 12px;

	.link {
		// border-bottom: 1px dotted $white-text;
		cursor: pointer;
		color: $primary;
	}
}
.agreement-text {
	margin-left: 6px;
	margin-top: 5px;
	color: $white-text;
	// font-family: 'TacticSans-Light';
}
.disabled {
	background: #959595;
	cursor: default;
}

.dropdown {
	margin-bottom: 10px;
	text-transform: uppercase;
	padding: 0 10px;
	font-size: 16px;
	height: 40px;
	overflow: visible;

	&:hover:not(.disabled) {
		opacity: 1;
		background: #f0f0f0;
	}
}

.dropdown-item {
	text-align: center;
	padding: 10px;
}

.callback-request-container .form-text {
  color: #000 !important
}