figure {
  display: block;
  width: 75px !important;
  max-width: 75px !important;
}

// figure:last-child {
//   display: none !important;
// }

.transition {
  -webkit-transition: top 400ms linear;
  -moz-transition: top 400ms linear;
  -ms-transition: top 400ms linear;
  -o-transition: top 400ms linear;
  transition: top 400ms linear;
}

.timeTo {
  font-family: Tahoma, Verdana, Aial, sans-serif;
  font-size: 28px;
  line-height: 108%;
  font-weight: bold;
  height: 75px;
}

.timeTo span {
  font-size: 18px;
  font-weight: normal;
  vertical-align: top;
  padding-right: 5px;

  @include to(400) {
    display: none;
  }
}

.timeTo.timeTo-white div {
  color: black;
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 38%, #cccccc 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(38%, #ffffff), color-stop(100%, #cccccc));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffffff 38%, #cccccc 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffffff 38%, #cccccc 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffffff 38%, #cccccc 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #ffffff 38%, #cccccc 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#cccccc', GradientType=0);
  /* IE6-9 */
}

.timeTo.timeTo-black div {
  color: #000;
  // background: #fff;
  /* Old browsers */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#45484d', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}

.timeTo.timeTo-black .timeTo-alert {
  background: #a74444;
  /* Old browsers */
  background: -moz-linear-gradient(top, #a74444 0%, #3f0000 67%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #a74444), color-stop(67%, #3f0000));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #a74444 0%, #3f0000 67%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #a74444 0%, #3f0000 67%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #a74444 0%, #3f0000 67%);
  /* IE10+ */
  background: linear-gradient(to bottom, #a74444 0%, #3f0000 67%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a74444', endColorstr='#3f0000', GradientType=0);
  /* IE6-9 */
}

.timeTo.timeTo-white .timeTo-alert {
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 35%, #e17373 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, #ffffff), color-stop(100%, #e17373));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffffff 35%, #e17373 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffffff 35%, #e17373 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffffff 35%, #e17373 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #ffffff 35%, #e17373 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e17373', GradientType=0);
  /* IE6-9 */
}

.timeTo figure {
  display: inline-block;
  margin: 0;
  padding: 0;
  padding-bottom: 15px;

  &:nth-child(1) {
    padding-left: 4px;
    background: url('/static/img/counter/1.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:nth-child(3) {
    padding-left: 4px;
    background: url('/static/img/counter/2.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:nth-child(5) {
    padding-left: 4px;
    background: url('/static/img/counter/3.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:nth-child(7) {
    padding-left: 4px;
    background: url('/static/img/counter/4.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.timeTo figcaption {
  text-align: center;
  /*font-size: 12px;*/
  line-height: 90%;
  font-weight: normal;
  color: #888;
  padding-right: 7px !important;
  padding-left: 0;
  margin-top: -8px;
}

.timeTo div {
  position: relative;
  display: inline-block;
  width: 30px !important;
  height: 34px !important;
  overflow: hidden;
  margin-right: 6px !important;

  ul {
    left: 1px !important;
    top: -13px !important;
  }
}

.timeTo div.first {
  margin-right: 0 !important;

  ul {
    left: 11px !important;
  }
}

.timeTo ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 3px;
}

.timeTo ul li {
  font-size: 30px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  list-style: none;
  color: black;
}