.lx-main {
  overflow: hidden;
}

.main-section {
  height: 100vh;
  width: 100vw;
  background-image: url("/static/img/lx/section-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding-left: 257px;

  @include to(480) {
    background-image: url("/static/img/lx/mobile/1.jpg");
  }

  @include to(768) {
    padding-left: 0;
    align-items: flex-start;
  }

  &__info {
    display: flex;
    flex-direction: column;
    z-index: 10;

    @include to(768) {
      justify-content: space-between;
      transform: translateY(0);
      padding-top: 90px;
      margin: 0 auto;
      height: 100%;
    }
  }

  &__title {
    font-size: 80px;
    font-weight: 300;
    color: white;
    margin-bottom: 40px;

    @include to(768) {
      font-size: 40px;
    }
  }

  &__subtitle {
    font-size: 35px;
    margin-bottom: 40px;
    color: #c78e66;
  }

  .btn {
    max-width: 215px;
    margin-bottom: 30px;

    @include to(768) {
      margin-top: auto;
    }
  }

  .btn__text_light {
    color: #fff;
  }
}
