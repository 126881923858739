// colors
$c-base: #fff;
$c-txt: #30363A;
$c-dark-gray:#30363A;
$c-very-dark: #181B1D;
$c-gray-divider: #DEDEDE;
$c-bronze-gold: #C78E66;
$c-gray-light: #CACACA;
$c-gray-back: #F2F2F2;
$c-gray-medium: #7D8082;
$default-text: $c-base;
$grey: #262626;
$primary: #ba271a;
$white-text: $c-base;
// paths
$svg: "../svg";
$img: "../img";
$fonts: "../fonts";

//transitions
$base-transition: .66s ease;
$base-long-transition: 1.5s ease-out;
$smooth-transition: 1.5s cubic-bezier(0,.14,.19,1);

//mixins

@function add_px($val) {
  @if type-of($val) == 'number' {
    @if unitless($val) == true {
      @return $val + 'px';
    } @else {
      @return $val;
    }
  } @else {
    @if str-index($val, 'p') != null {
      @return $val;
    } @else {
      @return $val + 'px';
    }
  }
}

@mixin to($max_width, $height: false) {
  @if $height == true  {
    @media screen and (max-height: add_px($max_width)) {
      @content;
    }
  } @else {
    @media screen and (max-width: add_px($max_width)) {
      @content;
    }
  }
}

@mixin from($min_width, $height: false) {
  @if $height == true  {
    @media screen and (min-height: add_px($min_width)) {
      @content;
    }
  } @else {
    @media screen and (min-width: add_px($min_width)) {
      @content;
    }
  }
}

@mixin scrollbar() {
  /* width */
  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    width: 8px;
    background: rgba(black,.8);
    height: 50%;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 7px -2px rgba(0, 0, 0, .1);
    width: 12px;
    background: rgba(white,.1);
    height: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(white,.3);
  }
}

@mixin no-scrollbar() {
  /* width */
  &::-webkit-scrollbar{
    width: 0;
    border-radius: 0;
    background: transparent;
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    width: 0;
    border-radius: 0;
    height: 0;
    background: transparent;
    display: none;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 0;
    border-radius: 0;
    height: 0;
    background: transparent;
    display: none;
  }
}

//fonts

@font-face {
  font-family: "TacticSansExd";
  src: url('../../static/fonts/TacticSansExd-Reg.woff2') format("woff2");
  font-weight: 400;
  font-style: normal
}
.menu__link {
  font-family:"TacticSansExd", serif;
}

@font-face {
  font-family: "TacticSansExdReg";
  src:
    url('../../static/fonts/TacticSansExd-RegReg.woff2') format('woff2'),
    url('../../static/fonts/TacticSansExd-RegReg.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TacticSansExdReg";
  src:
    url('../../static/fonts/TacticSansExd-BldReg.woff2') format('woff2'),
    url('../../static/fonts/TacticSansExd-BldReg.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src:
    url('../../static/fonts/WixMadeforDisplay-Bold.ttf') format('truetype'),;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}