.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto
}

.container {
  max-width: 1920px
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px
}

.col-lg-4,
.col-md-6,
.col-xl-3 {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px
}

*,
:after,
:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0
}

img {
  max-width: 100%;
  border: 0
}

:focus {
  outline: 0
}

button::-moz-focus-inner {
  border: none
}

button {
  font-family: inherit;
  color: inherit;
  border: none;
  border-radius: 0;
  background: 0 0
}

.svg-icon {
  overflow: hidden
}

.container {
  padding-left: 20px;
  padding-right: 20px
}

.menu {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 24px 20px;
}

.menu__back {
  position: relative;
  width: 100%;
  font-size: 12px/1;
  font-weight: 700;
  color: #fff;
  letter-spacing: .1em;
  text-transform: uppercase;
  text-align: center;
  padding: 19px 20px 19px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, .2)
}

.menu__back:after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 5px);
  left: 26px;
  width: 11px;
  height: 11px;
  border-bottom: 1px solid #c78e66;
  border-right: 1px solid #c78e66;
  transform: rotate(-225deg);
}

.menu__list {
  list-style: none;
}

.menu__link {
  display: block;
  font-weight: 500;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  padding: 18px 50px 18px 0;
  transition: color .2s, border-color .2s;
}

.menu__link_submenu {
  position: relative;
}

.menu__link_submenu-arrow {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 6px);
  right: 7px;
  width: 13px;
  height: 13px;
  border-bottom: 1px solid #c78e66;
  border-right: 1px solid #c78e66;
  transform: rotate(-45deg)
}

.menu-dropdown {
  z-index: 11;
  border-top: 1px solid rgba(255, 255, 255, .2);
  background-color: #181b1d
}

.menu-lev2__list {
  list-style: none;
  padding-bottom: 8px
}

.menu-lev2__item {
  font-size: 16px;
  line-height: 1.2;
  border-bottom: 1px solid rgba(255, 255, 255, .2)
}

.menu-lev2__link {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 15px 0
}

.menu-models__list {
  list-style: none
}

.menu-models__item-link {
  display: flex;
  align-items: center;
  line-height: 1;
  letter-spacing: .5px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 24px 0;
  border-bottom: 1px solid rgba(255, 255, 255, .2)
}

.menu-models__item-title {
  font-weight: 700;
  color: #fff;
  text-transform: uppercase
}

.menu-models__item-type {
  color: #cacaca;
  letter-spacing: .1em;
  text-transform: uppercase;
  margin-top: 10px
}

.menu-models__item-price {
  font-weight: 500;
  color: #c78e66;
  margin-top: 10px
}

.menu-models__item-img {
  flex-shrink: 0;
  margin-right: 20px
}

.menu-models__item-img img {
  width: 150px
}

.header-links {
  display: flex;
  flex-flow: column wrap;
  text-decoration: none;
  margin-right: 10%;

  @include to (1660) {
    margin-right: unset;
  }
}

.header-links__button {
  display: none !important;
  margin-left: 30px;
}

.header-links__phone {
  display: none;
  align-items: center;
  flex-direction: row;
  flex-grow: initial;
  font-weight: 500;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #fff;
  line-height: 1;
  text-decoration: none;
  // margin-left: 30px;
  transition: color .2s ease-in-out;

  &:hover {
    color: #c78e66;
  }
}

.header-links__icon {
  width: 20px;
  height: 20px;
  fill: #fff;
  margin-right: 20px
}

@supports (-webkit-overflow-scrolling:touch) {}

.header {
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  // background-color: #181b1d;
  -webkit-transition: top .3s linear;
  transition: top .3s linear
}

.header_hidden {
  top: -100px
}

.header-wrap {
  position: relative;
  width: 100%;
  // padding: 0 20px;
  max-width: 1920px;
  margin: 0 auto;
}


.header {
  &__logo {
    flex-shrink: 0;
    width: 126px;
    height: 24px;
    background-size: 100%;
    background: url('/static/img/logos/logo_exeed_white.svg') 0 0 no-repeat;
    background-position: center;
      @include to (414) {
        width: 62px;
        background-size: 100%;
        background-position: center;
      }
  }
  &__chery {
    flex-shrink: 0;
    width: 120px;
    height: 100%;
    background-size: 100%;
    background: url('/static/images/logo_chery.png') 0 0 no-repeat;
  }
}


.header__burger {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: inherit;
  cursor: pointer;
}

.header__burger i {
  display: block;
  width: 24px;
  height: 16px;
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='16' viewBox='0 0 24 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.248248 1.53453C0.193082 1.47997 0.144811 1.41858 0.103437 1.35038C0.0620621 1.28218 0.0344789 1.21398 0.0206873 1.14578C0.00689573 1.06394 0 0.975277 0 0.879795C0 0.620631 0.0827495 0.409207 0.248248 0.245524C0.413747 0.0818414 0.627516 0 0.889556 0H23.1104C23.3725 0 23.5863 0.0818414 23.7518 0.245524C23.9173 0.409207 24 0.620631 24 0.879795C24 1.13896 23.9173 1.3572 23.7518 1.53453C23.5863 1.69821 23.3725 1.78005 23.1104 1.78005H0.889556C0.627516 1.78005 0.413747 1.69821 0.248248 1.53453ZM23.1104 7.1202C23.3725 7.1202 23.5863 7.20205 23.7518 7.36573C23.9173 7.52941 24 7.74084 24 8C24 8.25916 23.9173 8.47059 23.7518 8.63427C23.5863 8.79795 23.3725 8.8798 23.1104 8.8798H0.889556C0.627516 8.8798 0.413747 8.79795 0.248248 8.63427C0.220665 8.60699 0.193082 8.57289 0.165499 8.53197C0.137916 8.49105 0.110333 8.45013 0.0827495 8.40921C0.0689579 8.36829 0.0551663 8.32736 0.0413747 8.28644C0.0275831 8.24552 0.0137916 8.2046 0 8.16368C0 8.10912 0 8.05456 0 8C0 7.74084 0.0827495 7.52941 0.248248 7.36573C0.413747 7.20205 0.627516 7.1202 0.889556 7.1202H23.1104ZM23.7518 14.4655C23.9173 14.6292 24 14.8406 24 15.0997C24 15.3589 23.9173 15.5772 23.7518 15.7545C23.5863 15.9182 23.3725 16 23.1104 16H0.889556C0.627516 16 0.413747 15.9182 0.248248 15.7545C0.220665 15.7272 0.193082 15.6999 0.165499 15.6726C0.151707 15.6317 0.13102 15.5976 0.103437 15.5703C0.0896452 15.5294 0.0758536 15.4953 0.0620621 15.468C0.0482705 15.4271 0.0344789 15.3862 0.0206873 15.3453C0.00689573 15.3043 0 15.2702 0 15.243C0 15.202 0 15.1543 0 15.0997C0 14.8406 0.0827495 14.6292 0.248248 14.4655C0.413747 14.3018 0.627516 14.2199 0.889556 14.2199H23.1104C23.3587 14.2199 23.5725 14.3018 23.7518 14.4655Z' fill='white'/%3e%3c/svg%3e") center center no-repeat
}

.menu__item {
  position: relative;
}

@media (min-width:768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .menu {
    padding: 32px 40px 48px 40px
  }

  .menu__back {
    padding-right: 40px;
    padding-left: 40px;
  }

  .menu__back:after {
    left: 46px;
  }

  .menu-lev2__wrap {
    margin-top: 32px;
  }

  .menu-lev2__list {
    padding-bottom: 0;
    margin-bottom: -4px;
  }

  .menu-lev2__item {
    border: none;
  }

  .menu-lev2__link {
    padding: 0;
    margin-bottom: 20px;
  }

  .menu-models__item-link {
    display: block;
    border: none;
    padding: 32px 0 0 0;
  }

  .menu-models__item-title {
    font-size: 24px;
    margin-top: 24px;
  }

  .menu-models__item-type {
    font-size: 12px;
    margin-top: 12px;
  }

  .menu-models__item-price {
    font-size: 18px;
    margin-top: 12px;
  }

  .menu-models__item-img {
    margin-right: 0;
  }

  .menu-models__item-img img {
    width: 66%;
  }

  .header-links {
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    border-top: 1px solid rgba(255, 255, 255, .2)
  }

  .header-links__icon {
    margin: 0 0 16px 0;
  }
  .header-links__phone {
    display: block;
  }
}

@media (min-width:1024px) {
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .menu {
    overflow-x: initial;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
    padding: 0 0 10px;
  }

  .menu__back {
    display: none;
  }

  .menu__list {
    display: flex;
    align-items: center;
  }

  .menu__item {
    position: static;
  }

  .menu__item:not(:first-child) {
    margin-left: 20px;
  }

  .menu__item:hover .menu__link {
    &::before {
      opacity: 1;
    }
  }

  .menu__item:hover .menu-dropdown {
    visibility: visible;
    opacity: 1;
  }

  .menu__link {
    position: relative;
    font-size: 12px;
    padding: 10px 0;

    &::before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 4px;
      opacity: 0;
      background-color: #c78e66;
      transition: opacity .2s ease-in-out;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 100%;
      height: 20px;
    }
  }

  .menu__link_submenu-arrow {
    display: none;
  }

  .menu-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    transition: opacity .3s, transform .3s;
  }

  .menu-lev2 {
    margin-bottom: 22px
  }

  .menu-lev2 .container {
    max-width: none;
    padding-left: 198px
  }

  .menu-lev2__wrap {
    margin-top: 40px
  }

  .menu-lev2__item {
    font-size: 14px
  }

  .menu-lev2__link {
    margin-bottom: 18px
  }

  .menu-models .container {
    max-width: none;
    padding-left: 198px
  }

  .menu-models__list {
    padding-bottom: 40px
  }

  .menu-models__item-title {
    font-size: 20px
  }

  .menu-models__item-type {
    font-size: 10px;
    margin-top: 10px
  }

  .menu-models__item-price {
    font-size: 16px;
    margin-top: 10px
  }

  .header-links {
    -ms-flex-item-align: center;
    align-self: center;
    padding: 0;
    border: 0;
    min-width: 180px;
  }

  .header-links__phone {
    // display: block;
  }

  .header-links__icon {
    margin: 0 0 0 15px;
  }

  .header-wrap {
    justify-content: flex-start;
    // padding-right: 40px;
    // padding-left: 40px;
  }

  .header__burger {
    display: none
  }
}

@media (min-width:1200px) {
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .container {
    padding-left: 80px;
    padding-right: 80px;
  }

  .menu-models__item-link {
    padding-top: 40px;
  }

  .menu__item:not(:first-child) {
    margin-left: 30px;
  }

  .header-links__icon {
    margin: 0 0 0 35px;
  }

  .header-links__button {
    display: flex !important;
  }
}

@media (min-width:1450px) {
  body {
    font-size: 18px;
    line-height: 1.7;
  }

  .container {
    padding-left: 112px;
    padding-right: 112px;
  }

  .menu-lev2__item {
    font-size: 16px;
  }

  .menu-lev2__link {
    margin-bottom: 20px;
  }

  .menu-models__item-title {
    font-size: 24px;
  }

  .menu-models__item-type {
    font-size: 12px;
    margin-top: 12px;
  }

  .menu-models__item-price {
    font-size: 18px;
    margin-top: 12px;
  }

  .header-links__icon {
    margin: 0 0 0 10px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
  }
}

@media (max-width:1023px) {
  .menu {
    position: fixed;
    z-index: 10;
    top: 55px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #181b1d;
    overflow-y: auto;
    transform: translateX(-100%);
    pointer-events: none;
  }

  .menu_close,
  .menu_open {
    transition: transform .3s;
  }

  .menu_open {
    -webkit-transform: none;
    transform: none;
    pointer-events: auto;
  }

  .menu__item {
    border-bottom: 1px solid rgba(255, 255, 255, .2);
  }

  .menu-dropdown {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow-y: auto;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    pointer-events: none
  }

  .menu-dropdown_close,
  .menu-dropdown_open {
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s
  }

  .menu-dropdown_open {
    -webkit-transform: none;
    transform: none;
    pointer-events: auto
  }
}