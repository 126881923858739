.main-nav {
  display: flex;
  align-items: center;
  // white-space: nowrap;
  margin-left: 30px;

  @include to(1440) {
    margin-left: 0;
  }

  @include to(1350) {
    width: 300px;
    flex-wrap: wrap;
  }

  &__item {
    color: #fff;
    font-size: 15px;
    line-height: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all 0.5s;
    border-bottom: 1px solid transparent;
    margin-right: 25px;

    @include to(1440) {
      font-size: 12px;
    }
    @include to(1350) {
      margin-bottom: 5px;
    }

    &_weight {
      &_bold {
        font-weight: 600;
      }
      &_reg {
        font-weight: 500;
      }
    }
    &_mobile {
      display: none;
      @include to(1200) {
        display: inline;
      }
    }
    // &:not(:last-child) {
    //     margin-right: 30px;
    // }
    &:hover {
      color: $c-bronze-gold;
      border-bottom: 1px solid $c-bronze-gold;
    }
    &:active {
      color: $c-gray-medium;
      border-bottom: 1px solid transparent;
    }
    &_dis {
      cursor: default;
      &:hover,
      &:active {
        color: #fff;
        border-bottom: 1px solid transparent;
      }
    }
    &_active {
      color: $c-gray-medium!important
    }

    &_models {
      margin-left: 50px;
      height: 80px;
      display: flex;
      align-items: center;
      position: relative;
      
      &:after {
        position: absolute;
        bottom: -1px;
        left: 0;
        content: '';
        height: 5px;
        width: 100%;
        background: #c78e66;
      }
    }
  }
  @include to(1200) {
    display: none;
  }

  .d-none {
    display: none;
  }
}

.mobile-nav {
  display: none;
  @include to(1200) {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    width: 100%;
    justify-content: center;
    background: $c-dark-gray;
    top: -1000%;
    opacity: 0;
    padding: 20px;
    transition: all 1s;
    // height: 100vh;
  }
  .main-nav__item {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    color: #fff;
    font-weight: normal;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 16px;
    margin-bottom: 15px;
    margin-right: 0;
    &-img {
      display: flex;
      justify-content: space-between;
    }
    &-testdrive {
      margin-top: 75px;
    }
  }
  &.is-active {
    top: 60px;
    opacity: 1;
  }
  &_page_txl {
    @include to(1200) {
      flex-wrap: wrap;
      .main-nav__item {
        margin: 5px 6px;
        position: relative;
        font-weight: 300;
        // &:not(:last-child) {
        //     &:after {
        //         position: absolute;
        //         content: '';
        //         width: 1px;
        //         height: 15px;
        //         background: #fff;
        //         opacity: 0.2;
        //         top: 0px;
        //         right: -6px;
        //     }
        // }
      }
    }
  }
  &__title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 36px;
    color: #fff;
  }
}

.nav-overlay {
  position: absolute;
  background: $c-dark-gray;
  padding: 50px 100px 50px 200px;
  top: 80px;
  width: calc(100% - 80px);
  left: 50%;
  transform: translateX(-50%);
  border-top: 1px solid rgba(255,255,255, 0.2);
  display: flex;
  justify-content: space-between;

  &__img {
    width: 66%;
    margin-bottom: 20px;
  }

  &__name {
    font-size: 24px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__type {
    font-size: 12px;
    text-transform: uppercase;
    color: #cacaca;
    margin-bottom: 12px;
  }

  &__price {
    font-size: 18px;
    text-transform: uppercase;
    color: #c78e66;
    font-weight: 600;
  }

  .d-none {
    display: none;
  }
}
