.test-drive {
  position: relative;
  background: url("/static/img/bg-testdrive.jpg") center no-repeat;
  background-size: cover;
  padding-top: 52%;
  &__black {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #181b1d 0%, rgba(24, 27, 29, 0) 100%);
    top: 0;
  }

  @include to(800) {
    background: url("/static/img/bg-testdrive.jpg") center no-repeat;
    background-size: cover;
    padding: 87px 0;
  }

  &_vx {
    background: url(../img/test-drive_vx.jpg) center no-repeat;
    background-size: cover;

    @include to(800) {
      background: url(../img/mobile/test-drive_vx.jpg) center no-repeat;
      background-size: cover;
    }

    &:after {
      background: linear-gradient(90deg, rgba(24, 27, 29, 0.77) 60%, rgba(24, 27, 29, 0) 100%);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      max-width: 960px;
      height: 100%;
      content: "";
      z-index: 0;

      @include to(800) {
        background: #181b1d;
        opacity: 0.8;
      }
    }
  }

  &__descr {
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #ffffff;
    width: 538px;
    margin-bottom: 60px;
  }
  @include to(800) {
    &__descr {
      width: 100%;
      padding: 0 20px;
      text-align: center;
    }
  }

  &_txl {
    background: url(../img/test-drive_txl.jpg) center no-repeat;
    background-size: cover;

    @include to(800) {
      background: url(../img/mobile/test-drive_txl.jpg) center no-repeat;
      background-size: cover;
    }

    &:after {
      background: linear-gradient(90deg, rgba(24, 27, 29, 0.77) 60%, rgba(24, 27, 29, 0) 100%);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      max-width: 960px;
      height: 100%;
      content: "";
      z-index: 0;

      @include to(800) {
        background: #181b1d;
        opacity: 0.8;
      }
    }
  }

  &__img {
    width: 100%;

    @include to(800) {
      display: none;
    }
  }
  &__form-wrapper {
    position: absolute;
    top: 50%;
    left: 13.5%;
    transform: translateY(-50%);
    z-index: 5;

    @include to(800) {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }
  }
  &__form {
    @include to(800) {
      margin: 0 auto;
    }
  }
  &__title {
    margin-bottom: 60px;

    @include to(1660) {
      margin-bottom: 60px;
    }
    @include to(800) {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  &__desc {
    max-width: 540px;
    font-weight: 400;
    color: #fff;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 60px;

    @include to(1660) {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 40px;
    }
    @include to(800) {
      font-size: 14px;
      line-height: 20px;
      margin: 0 auto 30px;
      max-width: 100%;
      text-align: center;
    }
  }
  &__btn {
    @include to(800) {
      margin: 0 auto;
    }
  }
  @include to(800) {
    .btn-test-drive-header {
      margin: 0 auto !important;
    }
  }
}
