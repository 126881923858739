.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;
  z-index: 110;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 13px;
    cursor: pointer;
    transition: transform .5s ease-in-out;
    z-index: 2;
  }

  &__logo {
    margin-bottom: 30px;

    @include to(800) {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }

  &__logo .logo__img {
    @include to(800) {
      margin-bottom: 30px;
      margin-right: 0;
    }
  }

  &__logo .logo__title {
    &:after {
      @include to(800) {
        left: 0;
        top: -15px;
        transform: none;
        width: 30px;
        height: 1px;
      }
    }
  }

  &__title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
    color: $c-gray-light;
    text-align: left;
    font-weight: 300;
  }

  &__btn {
    width: 100%;
    justify-content: space-between !important;
  }

  &__form {
    width: 100% !important;

    .form__group {
      margin-bottom: 15px;
    }

    .form__input {
      &:first-child {
        margin-bottom: 15px;
      }
    }
  }

  &.open {
    opacity: 1;
    visibility: visible;
    background-color: rgba(black, .77);

    .popup-wrapper {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__container {
    margin: 0 auto;
    background: $c-dark-gray;
    position: relative;
    max-width: 500px;
    padding: 30px;
  }
}