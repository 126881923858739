.section-map {
	min-height: 510px;
	position: relative;
	overflow: hidden;

	@include to(800) {
		min-height: auto;
	}

	.map-cont {
		min-height: 510px;

		@include to(800) {
			min-height: 200px;
		}
	}

	.contacts-block {
		position: absolute;
		top: 50%;
		left: 140px;
		transform: translateY(-50%);
		background: #fff;
		padding: 30px;
		z-index: 10;
		box-shadow: 0 0 10px 0 rgba(0, 49, 69, 0.1);

		@include to(1660) {
			left: 85px;
		}
		@include to(1200) {
			left: 40px;
		}
		@include to(800) {
			position: static;
			transform: none;
			width: 100%;
			padding: 20px 20px 40px;
		}

		&__title {
			font-size: 30px;
			line-height: 30px;
			font-weight: 400;
			margin-bottom: 10px;
			


			@include to(1660) {
				font-size: 24px;
				line-height: 24px;
			}
			@include to(800) {
				font-size: 18px;
				line-height: 20px;
			}
		}
		&__subtitle {
			font-weight: normal;
			font-size: 18px;
			color: #7D8082;
			margin-bottom: 30px;
		}
		&__list {
			margin-bottom: 30px;
		}
		&__item {
			display: flex;
			align-items: flex-start;

			&:not(:last-child) {
				margin-bottom: 20px;

				@include to(1660) {
					margin-bottom: 15px;
				}
			}
			
		}
		&__icon {

			width: 20px;
			margin-right: 20px;
		}
		&__text {
			font-size: 14px;
			line-height: 20px;
			font-weight: 400;

			&_theme {
				&_address {
					color: $c-gray-medium;
				}
				&_hours {
					color: $c-txt;
				}
			}

			@include to(1660) {
				font-size: 12px;
				line-height: 16px;
			}

			&-red {
				color: #ba271a;
			}
		}		
	}
}

.map {
	background-color: $c-dark-gray;
	padding: 20px;
	position: relative;

	.map-close {
		position: absolute;
		top: 10px;
		right: 10px;

		&__icon {
			width: 13px;
		}
	}

	&__logo {
		margin-bottom: 10px;
		max-width: 170px;
	}
	&__dealer {
		color: $c-gray-light;
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 300;
		margin-bottom: 10px;
	}
	&__address {
		color: $c-gray-light;
		font-weight: 300;
		font-size: 12px;
		margin-bottom: 3px;
	}
	&__hours {
		color: #fff;
		font-weight: 300;
		font-size: 12px;
		display: block;
		margin-bottom: 10px;
	}
	&__phone {
		margin-bottom: 15px;
	}
}