.page-nav {
    width: 100%;
    height: 60px;
    background: $c-dark-gray;

    @include to(1200) {
        display: none;
    }

    &__inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-size: 19px;
        letter-spacing: 2px;
        color: $c-gray-light;
        text-transform: uppercase;
        font-weight: 300;
        margin-right: 115px;
        white-space: nowrap;

        @include to(1660) {
            font-size: 15px;
            margin-right: 60px;
        }
    }
    &__list {
        width: calc(100% - 240px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;       

        &:before {
            position: absolute;
            content: '';
            top: 20px;
            left: 0;
            height: 20px;
            width: 1px;
            background: #fff;
            opacity: 0.2;

            @include to(800) {
                display: none;
            }
        }
        &:after {
            position: absolute;
            content: '';
            top: 20px;
            right: 0;
            height: 20px;
            width: 1px;
            background: #fff;
            opacity: 0.2;
        }

        @include to(1660) {
            width: calc(100% - 160px);
        }
    }
    &__item {
        height: 100%;
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 2px;
        transition: all 0.5s;
        position: relative;

        &:not(:first-child) {

            &:before {
                position: absolute;
                content: '';
                top: 20px;
                left: 0px;
                height: 20px;
                width: 1px;
                background: #fff;
                opacity: 0.2;
            }
        }

        &:hover {
            background: #fff;
            color: $c-bronze-gold;
            font-weight: 600;

            &:before {
                background: transparent;
            }
        }

        @include to(1660) {
            font-size: 9px;
            line-height: 12px;
        }
        @include to(1200) {
            width: 33.3%;
        }
    }
}