.hero {
  background-color: #181b1d;
  position: relative;
}

.hero__img {
  background: url('/static/img/Model_Range_Overall_Hero.jpg') center / cover no-repeat;
  height: 36.875vw;
}
@media (max-width: 1023px) {
  .hero__img {
    height: 36.875vw;
  }
}
@media (max-width: 767px) {
  .hero__img {
    background: url('/static/img/Model_Range_Overall_Hero.jpg') 90% center / cover no-repeat;
    height: 90vw;
  }
}

.hero__inner {
  display: flex;
  position: absolute;
  inset: 0px;
}
@media (max-width: 768px) {
  .hero__inner {
    position: unset;
    inset: unset;
  }
}

.hero__wrapper {
  align-content: center;
  display: grid;
  gap: 10px;
  height: 100%;
  grid-template-columns: 760px 1fr;
  padding: 10px 0;
}
@media (max-width: 768px) {
  .hero__wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 12px 0 36px;
  }
}

.hero__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-width: 768px) {
  .hero__info {
    gap: 10px;
  }
}

.hero__description {
  font-size: 36px;
  line-height: 1.4em;
  font-weight: 700;
  color: #fff;
}
@media (max-width: 1024px) {
  .hero__description  {
    font-size: 24px;
  }
}
@media (max-width: 390px) {
  .hero__description{
    font-size: 16px;
  }
}

.hero__title {
  font-size: 49px;
  font-weight: 700;
  font-family: 'TacticSansExdReg';
  line-height: 1.4em;
  color: #fff;
  text-transform: uppercase;
}
@media (max-width: 1440px) {
  .hero__title {
    font-size: 2.8vw;
  }
}
@media (max-width: 1024px) {
  .hero__title {
    font-size: 26px;
  }
}
@media (max-width: 365px) {
  .hero__title {
    font-size: 16px;
  }
}

.hero__benefit-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 7px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 0 0 40px;
}

.hero__benefit-list-item {
  position: relative;
  color: #fff;
  font-size: 24px;
  line-height: 32px;
}
@media (max-width: 1440px) {
  .hero__benefit-list-item {
    font-size: 1.6vw;
  }
}
@media (max-width: 1024px) {
  .hero__benefit-list-item {
    font-size: 12px;
    line-height: 16px;
  }
}
.hero__benefit-list-item::before {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 6L9 17L4 12' stroke-opacity='0.64' stroke-linecap='round' stroke-linejoin='round' stroke='%23FFFFFF'/%3E%3C/svg%3E%0A");
  border-color: #fff;
  top: 0;
  left: -40px;
  width: 24px;
  height: 24px;
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  opacity: .64;
}


.hero__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 530px;
}
@media (max-width: 375px) {
  .hero__form {
    grid-template-columns: 1fr 40px;
  }
}
@media (max-width: 768px) {
  .hero__form {
    max-width: unset;
  }
}

.hero__text {
  color: #fff;
  display: none;
  font-size: 12px;
  line-height: 1;
}
@media (max-width: 365px) {
  .hero__text {
    display: block;
  }
}

.hero__input {
  box-sizing: border-box;
  color: $c-base;
  width: 100%;
  height: 52px;
  padding: 0 20px;
  border: 1px solid #ffffff;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  background: transparent;
  text-align: left;
}
@media (max-width: 768px) {
  .hero__input {
    height: 40px;
    font-size: 12px;
    text-align: center;
  }
}

.hero__input::placeholder {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 2px;
  font-weight: 600;
}

.hero__btn {
  color: #ffffff;
  background: transparent;
  width: 100%;
  font-family: inherit;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 30px;
  border: 1px solid #ffffff;
  transition: all .2s linear;
}
@media (max-width: 768px) {
  .hero__btn {
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 5px 15px;
  }
}

.hero__btn:hover {
  border: 1px solid #c78e66;
  color: white;
  background: #c78e66;
}

.hero__btn-text {
  background: url('/static/img/icons/btn_arrow.png') 100% 50% no-repeat;
  display: block;
  text-align: center;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 2px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
@media (max-width: 375px) {
  .hero__btn-text {
    display: none
  }
}

.hero__btn:hover .hero__btn-text  {
  background-image: url('/static/img/icons/btn_arrow_wt.png');
}

.hero__btn:hover .hero__btn-text  {
  background-image: url('/static/img/icons/btn_arrow_wt.png');
}

.hero__icon {
  display: none;
}

@media (max-width: 375px) {
  .hero__icon {
    display: block;
    width: 8px;
    height: 15px;
    background-image: url('/static/img/icons/btn_arrow.png');
  }
}