.section-credit-supply {
  width: 49%;
  padding: 90px 80px 90px 20px;
  background: #F2F2F2;

  @include to(1600) {
    padding: 30px 80px 30px 20px;
  }

  @include to(1400) {
    width: 100%;
    padding: 0px 30px 30px 30px;
  }

  &_black {
    background-color: #2B3336;
    padding: 90px 0 90px 80px;

    @include to(1600) {
      padding: 30px 0 30px 80px;
    }

    @include to(1400) {
      padding: 0 30px 30px 30px;
    }

    .section-supply__img-tlx-new {
      width: 45.1%;
    }

    .section-credit-supply__overlay {
      color: #242A2F;
    }

    .section-credit-supply__item {
      color: #fff;
    }

    .section-credit-supply__description {
      color: #fff;
    }

    .btn_theme_dark {
      background-color: #fff;
    }

    .btn__text_theme_dark {
      color: #30363A;
    }

    .btn_border_dark {
      border-color: #7D8082;
    }

    .btn__text_theme_light {
      color: #fff;
    }

    .section-credit-supply__benefits-item {

      &:nth-child(1) .section-credit-supply__benefits-item-inner,
      &:nth-child(3) .section-credit-supply__benefits-item-inner {

        &::before {
          border-top: 25px solid #2B3336;
          background-color: #181b1d;
        }
      }

      &:nth-child(2) .section-credit-supply__benefits-item-inner,
      &:nth-child(4) .section-credit-supply__benefits-item-inner {

        &::after {
          border-top: 25px solid #2B3336;
          background-color: #181b1d;
        }
      }
    }

    .section-credit-supply__benefits-item-inner {
      color: #181B1D;
      background-color: #181B1D;
    }
  }

  &_darken {
    background-color: #0f1417;

    .section-credit-supply__overlay {
      color: #000;
    }

    .section-credit-supply__item {
      color: #fff;
    }

    .btn_theme_dark {
      background-color: #fff;
    }

    .btn__text_theme_dark {
      color: #30363A;
    }

    .btn_border_dark {
      border-color: #7D8082;
    }

    .btn__text_theme_light {
      color: #fff;
    }

    .section-credit-supply__description {
      color: #fff;
    }

    .section-credit-supply__benefits-item {

      &:nth-child(1) .section-credit-supply__benefits-item-inner,
      &:nth-child(3) .section-credit-supply__benefits-item-inner {

        &::before {
          border-top: 25px solid #0f1417;
          background-color: #30363A;
        }
      }

      &:nth-child(2) .section-credit-supply__benefits-item-inner,
      &:nth-child(4) .section-credit-supply__benefits-item-inner {

        &::after {
          border-top: 25px solid #0f1417;
          background-color: #30363A;
        }
      }
    }

    .section-credit-supply__benefits-item-inner {
      color: #30363A;
      background-color: #30363A;
    }
  }

  &__title {
    position: relative;
    padding-top: 40px;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 500;
    font-size: 54px;
    line-height: 1;
    color: #E1E1E1;
  }

  &__price {
    font-size: 24px;
    font-weight: 600;
    color: #C78E66;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &__item {
    display: flex;
    align-items: center;
    width: calc(50% - 5px);
    margin-bottom: 10px;

    @include to(767) {
      width: 100%;
    }

    &:nth-child(2n+1) {
      margin-right: 10px;
    }

    img {
      margin-right: 5px;
    }

    span {
      font-size: 16px;
      font-weight: 400;

      span {
        font-weight: 600;
        color: #C78E66;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
  }

  &__button {
    @include to(450) {
      margin-top: 10px;
    }

    &:nth-child(1) {
      margin-right: 10px;

      @include to(450) {
        margin-right: 0;
      }
    }

    &:nth-child(1),
    &:nth-child(2) {
      width: calc(50% - 5px);

      @include to(450) {
        width: 100%;
      }
    }

    &:nth-child(3) {
      margin-top: 10px;
    }
  }

  &__benefits {
    display: flex;
    flex-wrap: wrap;
    color: $c-base;
    margin-top: 24px;
  }

  &__benefits-item {
    width: calc(50% - 5px);
    margin-bottom: 10px;

    &:nth-child(1),
    &:nth-child(3) {
      margin-right: 10px;
    }

    &:nth-child(1) .section-credit-supply__benefits-item-inner,
    &:nth-child(3) .section-credit-supply__benefits-item-inner {
      padding-right: 10px;

      &::before {
        content: "";
        z-index: 1;
        left: 0;
        bottom: 0;
        border: 25px solid transparent;
        border-top: 25px solid #f2f2f2;
        transform: rotate(-90deg);
        background-color: #fff;
      }
    }

    &:nth-child(2) .section-credit-supply__benefits-item-inner,
    &:nth-child(4) .section-credit-supply__benefits-item-inner {
      padding-left: 10px;

      &::after {
        content: "";
        z-index: 1;
        left: 0;
        bottom: 0;
        border: 25px solid transparent;
        border-top: 25px solid #f2f2f2;
        transform: rotate(90deg);
        background-color: #fff;
      }
    }
  }

  &__benefits-item-inner {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #fff;
  }

  &__benefits-item-icon {
    width: 28px;
    height: 28px;
    margin-right: 10px;

    @include to(450) {
      display: none;
    }
  }

  &__benefits-item-text {
    flex-grow: 1;
    font-weight: 500;
    font-size: 12px;
    color: #C78E66;
  }

  &__description {
    font-size: 13px;
    margin-top: 5px;
  }
}