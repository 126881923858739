::selection {
  background-color: $c-gray-medium;
  color: white;
}

.disallow-transitions {
  * {
    transition: none !important;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  &.is-mobile-overflow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
  }
}

body {
  display: flex;
  flex-direction: column;
  height:100%;
  font-family: 'Montserrat', sans-serif;

  &.is-mobile-overflow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow-y: scroll;
  }
}

#supportTrigger {
  display: none !important;
}

div {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

p,
li {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  max-width: 100%;
  color: $c-txt;
}

a {
  color: $c-txt;
  text-decoration: none;
  letter-spacing: 1px;
  cursor: pointer;
}

.footnote,
.footnote * {
  margin-top: 5px;
  font-size: 0.694rem;
  color: #707072;
  cursor: default;
  transition: color $base-transition;
}

a:hover {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

.responsive-media {
  background-position: center;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    padding-top: 100%
  }

  &>picture {
    background-position: center
  }

  &>img,
  &>picture,
  &>picture>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.no-scroll {
  overflow: hidden;
}

.d-none {
  display: none;
}

.container {
  // width: 100%;
  // height: 100%;
  // margin: 0 auto;
  // display: flex;
  // justify-content: center;
  // // align-items: center;
  // flex-direction: column;

  &_size_h {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 100%;

    @include to(1200) {
      padding: 0 20px;
      height: auto;
    }
  }

  &_size_c {
    max-width: 1640px;

    @include to(1660px) {
      max-width: 100%;
      padding: 0 90px;
    }

    @include to(1200) {
      padding: 0 40px;
    }

    @include to(800) {
      padding: 0 20px;
    }
  }

  &_size_n {
    max-width: 100%;
    padding: 0 112px;

    @include to(1200) {
      padding: 0 40px;
    }

    @include to(800) {
      padding: 0 20px;
    }
  }
}


[js-popup] {
  cursor: pointer;
}

.min-w-\[370px\] {
  min-width: 370px;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.max-w-\[330px\] {
  max-width: 330px;
}

.max-w-\[238px\] {
  max-width: 238px;
}

.max-w-\[214px\] {
  max-width: 214px;
}

.w-full {
  width: 100%;
}

.w-\[180px\] {
  width: 180px;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.md\:flex-col {
  @include to(767) {
    flex-direction: column;
  }
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.text-left {
  text-align: left;
}

.pt-20 {
  padding-top: 80px;
}

.pt-12 {
  padding-top: 48px;
}

.md\:pt-12 {
  @include to(767) {
    padding-top: 48px;
  }
}

.pl-5 {
  padding-left: 20px;
}

.mt-8 {
  margin-top: 32px;
}

.mb-16 {
  margin-bottom: 32px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-4 {
  margin-bottom: 8px;
}

.md\:mb-4 {
  @include to(767) {
    margin-bottom: 16px;
  }
}

.mb-8 {
  margin-bottom: 24px;
}

.mr-0 {
  margin-right: 0;
}

.mr-4 {
  margin-right: 16px;
}

.md\:mr-0 {
  @include to(767) {
    margin-right: 0;
  }
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.ml-auto {
  margin-left: auto;
}

.text-lg {
  font-size: 18px;
  line-height: 28px;
}

.font-normal {
  font-weight: 400;
}

.hidden {
  display: none;
}