.section-brand-vx {
    display: flex;
    width: 100%;
    // background: $c-dark-gray;
    @include to(1300) {
        flex-direction: column;
    }
    .about-brand {
        width: 25%;
        background: $c-very-dark;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 40px;
        min-height: 442px;
        @include to(1300) {
            width: 100%;
        }
        @include to(800) {
            padding: 40px 20px;
        }
        &__subtitle {
            padding: 7px 15px;
            color: #FFF;
            font-weight: 400;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 1px;
            margin-bottom: 20px;
            background-color: rgba(199, 142, 102, 0.1);
            @include to(1660) {
                font-size: 16px;
                line-height: 16px;
            }
            @include to(800) {
                font-size: 12px;
                line-height: 15px;
            }
        }
        &__title {
            margin-bottom: 20px;
        }
        &__price {
            color: $c-bronze-gold;
            letter-spacing: 2px;
            text-transform: uppercase;

            margin-bottom: 20px;
            @include from(1301) {
                margin-bottom: 65px;
            }
            @include to(414) {
                font-size: 20px;
            }
            @include from(415) {
                font-size: 29px;
            }
        }
        &__desc {
            font-size: 18px;
            line-height: 32px;
            font-weight: 400;
            color: $c-txt;
            margin-bottom: 40px;
            @include to(1660) {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 30px;
            }
            @include to(800) {
                line-height: 25px;
            }
        }
    }
    &__img-bl {
        width: 50%;
        position: relative;
        background: url(../img/imagevx.jpg) center no-repeat;
        background-size: cover;
        padding-top: 40%;
        @include to(1300) {
            width: 100%;
            padding-top: 100%;
        }
        @include to(800) {
            background: url(../img/imagevx.jpg) center no-repeat;
            background-size: cover
        }
        .facts {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: transparent;
            @include to(800) {
                position: static;
                transform: none;
            }
            &__item {
                width: 395px;
                padding: 60px 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                &:not(:last-child) {
                    border-bottom: 1px solid $c-bronze-gold;
                }
                @include to(1660) {
                    padding: 30px 0;
                    width: 220px;
                }
                @include to(800) {
                    width: 100%;
                }
            }
            &__num {
                font-size: 80px;
                line-height: 80px;
                font-weight: 300;
                margin-bottom: 10px;
                color: #fff;
                transition: all 0.5s;
                white-space: nowrap;
                @include to(1660) {
                    font-size: 60px;
                    line-height: 60px;
                }
                @include to(800) {
                    font-size: 40px;
                    line-height: 40px;
                }
            }
            &__title {
                font-size: 18px;
                line-height: 18px;
                color: $c-gray-light;
                font-weight: 400;
                transition: all 1s;
                @include to(1660) {
                    font-size: 14px;
                    line-height: 14px;
                }
            }
            .facts__item:hover .facts__title,
            .facts__item:hover .facts__num {
                color: $c-bronze-gold;
            }
        }
    }
    &__img {
        width: 100%;
        @include to(800) {
            display: none;
        }
    }
    .benefits {
        width: 100%;
        &__item {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            width: 100%;
            justify-content: space-between;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        &__titlewrap {
            display: flex;
        }
        &__count {
            color: $c-base;
            font-weight: 600;
            font-size: 14px;
            padding-left: 5px;
        }
        &__icon {
            height: 20px;
            width: 20px;
            margin-right: 23px;
            @include to(800) {
                margin-right: 15px;
            }
        }
        &__title {
            font-size: 18px;
            line-height: 18px;
            color: $c-gray-light;
            font-weight: normal;
            transition: all 0.5s;
            @include to(1660) {
                font-size: 14px;
                line-height: 18px;
            }
        }
        &-arrows {
            display: flex;
            flex-direction: column;
            @include from(1301) {
                margin-bottom: 80px;
            }
            @include to(1300) {
                flex-direction: row;
                flex-wrap: wrap;
            }
            &-list {
                display: flex;
                align-items: center;
                width: 25%;
                background: #181B1D;
                @include to(1300) {
                    width: 100%;
                    padding-right: 40px;
                    padding-left: 40px;
                    padding-bottom: 20px;
                }
                @include to(800) {
                    padding-right: 20px;
                    padding-left: 20px;
                }
                @include from(1280) {
                    // padding-right: 40px;
                    justify-content: flex-end;
                }
            }
            &-item-wrapper {
                display: flex;
                flex-direction: row;
                margin-bottom: 15px;
                @include to(1300) {
                    width: calc(50% - 10px);
                }
                &:nth-child(odd) {
                    @include to(1300) {
                        margin-right: 20px;
                    }
                }
                &:nth-child(even) {
                    @include to(1300) {
                        .benefits-arrows-item {
                            .benefits-arrows-item-inner {
                                padding-right: 0;
                                padding-left: 20px;
                                @include to(600) {
                                    padding-left: 10px;
                                }
                                @include to(400) {
                                    padding-right: 10px;
                                }
                            }
                            &::before {
                                display: none;
                            }
                            &::after {
                                content: '';
                                z-index: 1;
                                left: 0px;
                                bottom: 0px;
                                border: 2rem solid transparent;
                                border-top: 2rem solid #181B1D;
                                transform: rotate(90deg);
                                background-color: #30363A;
                                @include to(400) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            &-item {
                display: flex;
                height: 4rem;
                width: 100%;
                &::before {
                    content: '';
                    z-index: 1;
                    left: 0px;
                    bottom: 0px;
                    /* Положение треугольника */
                    border: 2rem solid transparent;
                    border-top: 2rem solid #181B1D;
                    transform: rotate(-90deg);
                    background-color: #30363A;
                    @include to(400) {
                        display: none;
                    }
                }
            }
            &-item-inner {
                display: flex;
                justify-content: space-between;
                width: calc(100% - 4rem);
                padding-right: 20px;
                background-color: #30363A;
                @include to(600) {
                    padding-right: 10px;
                }
                @include to(400) {
                    width: 100%;
                    padding-left: 10px;
                }
            }
            &-item__icon {
                width: 35px;
                height: 35px;
                margin-top: auto;
                margin-right: 10px;
                margin-bottom: auto;
                @include to(600) {
                    display: none;
                }
            }
            &-item__text {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                color: #fff;
                flex-grow: 1;
                @include to(600) {
                    font-size: 12px;
                }
            }
            &-item__arrow {
                width: 8px;
                height: auto;
                margin-left: 10px;
                @include to(600) {
                    width: 6px;
                }
            }
        }
        .benefits__item:hover .benefits__title {
            color: $c-bronze-gold;
        }
    }
    .buttons {
        &__wrap {
            width: 100%;
            margin-top: 50px;
            // display: grid;
            // grid-template-columns: repeat(2, auto);
            // column-gap: 10px;
            @include from (1920) {
                display: block;
                @apply space-y-4;
            }
            @include to(1500) {
                display: block;
            }
            @include to(1300) {
                display: flex;
                width: 100%;
            }
            @include to(500) {
                display: block;
            }
            span {
                font-size: 11px;
            }
            button {
                margin-top: 0;
            }
            .btn {
                padding-left: 0 !important;
                padding-right: 0 !important;
                margin-bottom: 10px;
                margin-right: 8px;

                &:first-child {
                    @include to(1500) {
                        margin-bottom: 10px;
                    }
                    @include to(1300) {
                        margin-bottom: 0;
                        margin-right: 10px;
                    }
                    @include to(500) {
                        margin-bottom: 10px;
                        margin-right: 0;
                    }
                }
                @include to(1300) {
                    width: 50%;
                }
                @include to(500) {
                    width: 100%;
                }
            }
            .btn__text {
                font-size: 10px;
            }
        }
    }
    @include to(1300) {
        .about-brand {
            align-items: flex-end;
        }
    }
}