#main-slider-banner .swiper {
    width: 100%;

    &-button-prev,
    &-button-next {
        color: #c78e66!important;
        &::after {
            @include from(1200) {
                font-size: 75px!important;
            }
        }
    }
    &-pagination {
        &-bullet {
            @include from (1200) {
                width: 1rem!important;
                height: 1rem!important;
            }
        }
        span {
            background: #c78e66;
            pointer-events: auto;
        }
    }
    &-slide {
        position: relative;

        // height: 0;
        overflow: hidden;
        @include to(600) {
            // padding-bottom: 90%;
        }
        @include from(601) {
            // padding-bottom: 36.25%;
        }
        img {
            // position: absolute;
            // top: 0;
            // left: 0;
            // width: 100%;
            // height: 100%;
            // object-fit: cover;
            // object-position: left;
        }
    }
    &-pagination {
        caret-color: transparent;
        pointer-events: none;
    }
}
