.slick {
  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all $base-transition;
    padding: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 25;

    @include to(600) {
      border-radius: 50%;
      padding: 5px;
      background-color: black;
      background-size: 60%;

      width: 35px;
      height: 35px;
    }

    &--prev {
      left: -50px;
      background-image: url(/static/img/svg/left-arrow.svg);

      @include to(600) {
        left: 0;
      }
    }

    &--next {
      right: -50px;
      background-image: url(/static/img/svg/right-arrow.svg);

      @include to(600) {
        right: 0;
      }
    }
  }

  &-disabled {
    opacity: 0;
    pointer-events: none;
  }

  &-track {
    display: flex!important;
    align-items: stretch;
  }

  &-slide {
    float: none!important;

    img {
      display: block;
      margin: 0 auto;
    }

    &.flex-item {
      margin: 0 20px!important;
    }
  }
}
