.promo-tag {
  position: relative;
  pointer-events: auto;

  @media (max-width: 768px) {
    position: absolute;
    top: 0px;
    right: 0px;
    display: contents;
  }
  @media (min-width: 1920px) {
    align-self: flex-start;
  }

  label {
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
  }

  &__info {
    flex-shrink: 0;
    color: $c-bronze-gold;
    width: 20px;
    height: 20px;
    
  }
  &_title {
    align-items: center;
    display: flex;
    background-color: #fff;
    position: absolute;
    top: 10px;
    gap: 6px;
    width: fit-content;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 10px;
    @media (min-width: 1024px) {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    // span {
    //   display: inline-block;
    //   color: #fff;
    //   background-color: #000;
    //   border-radius: 10px;
    //   text-align: center;
    //   font-size: 8px;
    //   width: 12px;
    //   height: 12px;
    //   bottom: 1px;
    //   position: relative;

    //   @media (min-width: 1024px) {
    //     padding: 0px 7px;
    //     font-size: 10px;
    //     width: 18px;
    //     height: 18px;
    //   }
    // }
  }

  &_info {
    display: none;
    position: absolute;
    top: 50px;
    background-color: #fff;
    padding: 3px 10px;
    text-align: left;
    font-weight: 500;
    font-size: 10px;
    @media (max-width: 768px) {
      width: max-content;
    }
    @media (min-width: 1024px) {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    @media (max-width: 1024px) {
      top: 45px;
    }

    &:before { 
      content: ' ';
      position: absolute;
      /* width: 0; */
      /* height: 0; */
      left: 100px;
      top: -19px;
      border: 10px solid;
      border-color: transparent transparent #fff transparent;

      @media (max-width: 1024px) {
        left: 74px;
      }
    }
  }

  &-switch {
    display: none;
  }

  &-switch:checked ~ .promo-tag_info {
    display: block;
  }
}