.form {
  width: 400px;

  @include to(1660) {
    width: 375px;
  }
  @include to(800) {
    width: 280px;
  }
  
  &__group {
    margin-bottom: 60px;
    position: relative;

    @include to(1660) {
      margin-bottom: 40px;
    }
  }
  
  &__input {
    width: 100%;
    height: 50px;
    padding: 5px 16px;
    color: $c-gray-light;
    border: 1px solid $c-gray-medium;
    background: rgba(255, 255, 255, 0.1);
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    

    @include to(1660) {
      height: 40px;
      font-size: 12px;
    }

    &:first-child {
      margin-bottom: 30px;
    }

    &:focus {
      border: 1px solid $c-bronze-gold;
      outline: none;
    }

    &_white {
      background: #fff;
    }
  }

  &__select {
    width: 100%;
    height: 50px;
    padding: 5px 16px;
    color: $c-gray-light;
    border: 1px solid $c-gray-medium;
    background: rgba(255, 255, 255, 0.1);
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;

    @include to(1660) {
      height: 40px;
      font-size: 12px;
    }

    &:focus {
      border: 1px solid $c-bronze-gold;
      outline: none;
    }

    &_white {
      background: #fff;
    }
  }

  label.error {
    position: absolute;
    bottom: -12px;
    left: 0;
    max-width: 280px;
    color: #ba271a;
    font-size: 9px;
  }

  .agreement {
    display: flex;
    align-items: center;
    margin-top: 25px;

    @include to(800) {
      margin-top: 15px;
    }

    &__check {
      margin-right: 10px;
    }
    
    input[type="checkbox"] {
      display: none;

      &:checked + .agreement__text:before {
        position: absolute;
        content: '\2713';
        font-size: 10px;
        color: $c-gray-divider;
        text-align: center;
        line-height: 13px;    
        
        @include to(800) {
          font-size: 8px;
          line-height: 10px; 
        }
      }
    }   
    &__text {
      color: $c-gray-medium;
      font-size: 9px;
      position: relative;
      padding-left: 20px;

      @include to(800) {
        font-size: 7px;
      }

      &:before {
        width: 13px;
        height: 13px;
        border-radius: 0;
        outline: none;
        margin-right: 9px;
        background: $c-gray-medium;
        position: absolute;
        content: '';
        left: 0px;
        top: -2px;
        cursor: pointer;
        box-sizing: border-box;

        @include to(800) {
          width: 10px;
          height: 10px;
        }
      }
    } 
    &__link {
      color: $c-gray-medium;
      font-size: 9px;
      text-decoration: underline;

      @include to(800) {
        font-size: 7px;
      }
    }
  } 
}

label.error {
  position: absolute;
  bottom: -12px;
  left: 0;
  max-width: 280px;
  color: $c-bronze-gold;
  font-size: 9px;
}