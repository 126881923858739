$color_1: #ffffff;
$color_2: transparent;
$color_3: #c78e66;
$color_4: black;
$font-family_1: inherit;
$border-color_1: #c78e66;
$border-color_2: white;

@-webkit-keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}
@keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}
* {
  scroll-behavior: smooth;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}
.btn-tradein {
  position: relative;
  height: 45px;
  display: inline-flex;
  place-items: center;
  padding: 0 20px;
  text-transform: uppercase;
  font-family: $font-family_1;
  font-weight: 500;
  font-size: 16px;
  border: none;
  overflow: hidden;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    &::before {
      content: "";
      opacity: 0.5;
      display: block;
      width: 30px;
      height: 200px;
      background: -webkit-gradient(
          linear,
          right top,
          left top,
          from(rgba(255, 255, 255, 0)),
          color-stop(rgba(255, 255, 255, 0.8)),
          to(rgba(255, 255, 255, 0))
        )
        no-repeat -2px 0;
      background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0)
        )
        no-repeat -2px 0;
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3ffffff', endColorstr='#b3ffffff',GradientType=0 );
      position: absolute;
      top: -40px;
      left: -30px;
      z-index: 1;
      transform: rotate(45deg);
      transition: all 0.1s;
      animation-name: slideme;
      animation-duration: 3s;
      animation-delay: 0.05s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
}
.btn-tradein--primary {
  background: var(--c-primary);
  border-color: $border-color_1;
  color: $color_1;
  &:hover {
    background: var(--c-primary-light);
  }
}
input {
  outline: none;
  transition: 0.2s ease-in-out;
  border: 1px solid #eee;
  border-bottom-width: 2px;
  &:active {
    border-color: $border-color_1;
  }
  &:focus {
    border-color: $border-color_1;
  }
  &:hover {
    border-color: $border-color_1;
  }
  &::focus {
    &::-webkit-input-placeholder {
      color: $color_2;
    }
    &:-ms-input-placeholder {
      color: $color_2;
    }
    &::-ms-input-placeholder {
      color: $color_2;
    }
    &::placeholder {
      color: $color_2;
    }
  }
}
.with-arrow {
  position: relative;
  padding-right: 45px !important;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% - 6px);
    right: 20px;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: border 0.2s;
    transition: border 0.2s;
  }
  &:hover {
    &::after {
      border-color: $border-color_1;
    }
  }
}
.btn-tradein--secondary.with-arrow {
  &:not(:hover) {
    &::after {
      border-color: $border-color_2;
    }
  }
}
.btn-tradein--primary.with-arrow {
  &:hover {
    &::after {
      border-color: $border-color_2;
    }
  }
}
.trade-in {
  padding: 3rem 0;
  background: #181b1d;
}
.trade-in__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 1rem;
}
.trade-in__title {
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 1.25;
  text-transform: uppercase;
  span {
    color: $color_3;
  }
}
.trade-in__subtitle {
  margin: 0 auto 1.5rem;
  max-width: 800px;
  font-size: 14px;
  line-height: 1.25;
  opacity: 0.6;
  text-align: center;
}
.trade-in__form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -1rem;
  .form-row {
    flex: 0 0 100%;
    padding: 0 1rem;
    margin-bottom: 1.6rem;
  }
  input {
    display: block;
    height: 50px;
    width: 100%;
    padding: 0px 1rem;
    font-size: 1rem;
    background: #eee;
    border: 2px solid transparent;
    color: $color_4;
    border-radius: 0px;
  }
}
.trade-in__submit {
  width: 100%;
  white-space: nowrap;
}
@media (pointer: coarse) {
  .btn-tradein--primary {
    &:hover {
      &:not(.active) {
        background: var(--c-primary);
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .trade-in__title {
    font-size: 32px;
  }
  .trade-in__subtitle {
    font-size: 24px;
  }
}
@media screen and (min-width: 960px) {
  .trade-in__form {
    .form-row {
      flex: 0 0 280px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .trade-in__title {
    font-size: 40px;
  }
  .trade-in__subtitle {
    margin-bottom: 2.75rem;
  }
}
