.accordion-single {
  .accordion-single-title img {
    transition: transform .2s ease-in-out;
  }

  &.open {
    .accordion-single-title img {
      transform: rotate(180deg);
    }
  }
}