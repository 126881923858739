.section-list {
    display: flex;

    @include to(1300) {
        flex-direction: column;
    }

    &__img-bl {
        width: 50%;
        padding-top: 50%;
        transition: all 0.5s; 

        &_vx {
            &_design {
                background: url(../img/design-vx.jpg) center no-repeat;
                background-size: cover;

                @include to(800) {
                    background: url(../img/mobile/design-vx.jpg) center no-repeat;
                    background-size: cover;
                }
            }

            &_technologies {
                background: url(../img/technologies-vx.jpg) center no-repeat;
                background-size: cover;

                @include to(800) {
                    background: url(../img/mobile/technologies-vx.jpg) center no-repeat;
                    background-size: cover;
                }
            }

            &_comfort {
                background: url(../img/comfort-vx.jpg) center no-repeat;
                background-size: cover;

                @include to(800) {
                    background: url(../img/mobile/comfort-vx.jpg) center no-repeat;
                    background-size: cover;
                }
            }

            &_safe {
                background: url(../img/safe-vx.jpg) center no-repeat;
                background-size: cover;

                @include to(800) {
                    background: url(../img/mobile/safe-vx.jpg) center no-repeat;
                    background-size: cover;
                }
            }

            &_quality {
                background: url(../img/quality-vx.jpg) center no-repeat;
                background-size: cover;

                @include to(800) {
                    background: url(../img/mobile/quality-vx.jpg) center no-repeat;
                    background-size: cover;
                }
            }
        }

        &_txl {
            &_design {
                background: url(../img/design-txl.jpg) center no-repeat;
                background-size: cover;

                @include to(800) {
                    background: url(../img/mobile/design-txl.jpg) center no-repeat;
                    background-size: cover;
                }
            }

            &_technologies {
                background: url(../img/technologies-txl.jpg) center no-repeat;
                background-size: cover;

                @include to(800) {
                    background: url(../img/mobile/technologies-txl.jpg) center no-repeat;
                    background-size: cover;
                }
            }

            &_comfort {
                background: url(../img/comfort-txl.jpg) center no-repeat;
                background-size: cover;
                
                @include to(800) {
                    background: url(../img/mobile/comfort-txl.jpg) center no-repeat;
                    background-size: cover;
                }
            }

            &_safe {
                background: url(../img/safe-txl.jpg) center no-repeat;
                background-size: cover;

                @include to(800) {
                    background: url(../img/mobile/safe-txl.jpg) center no-repeat;
                    background-size: cover;
                }
            }

            &_quality {
                background: url(../img/quality-txl.jpg) center no-repeat;
                background-size: cover;

                @include to(800) {
                    background: url(../img/mobile/quality-txl.jpg) center no-repeat;
                    background-size: cover;
                }
            }
        }

        @include to(1300) {
            width: 100%;
            padding-top: 100%;
            order: 1;
        }
    }
    &__img {
        width: 100%;
    }

    .list-block {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 140px 140px 40px;

        &_theme {
            &_light {
                background: $c-gray-back;
            }
            &_dark {
                background: $c-dark-gray;
            }
            &_very-dark {
                background: $c-very-dark;
            }
            &_white {
                background: #fff;
            }
        }    

        @include to(1660) {
            padding: 85px;
        }
        @include to(1300) {
            width: 100%;
            padding: 40px;
        }
        @include to(800) {
            padding: 40px 20px;
        }

        &__title {
            margin-bottom: 40px;

            @include to(1660) {
                margin-bottom: 30px;
            }
            @include to(800) {
                margin-bottom: 25px;
            }
        }

        .list-details {
            width: 100%;

            &__item {
                width: 100%;
                padding: 20px 0;
                border-bottom: 1px solid $c-gray-light;
                cursor: pointer;
                
            }

            &__title {
                font-size: 16px;
                line-height: 18px;
                font-weight: 600;
                position: relative;
                transition: all 0.5s;
                display: inline;

                &:after {
                    position: absolute;
                    content: '';
                    top: 0px;
                    right: -20px;
                    width: 10px;
                    height: 18px;
                    background: url('../img/icons/arrow-right.png') no-repeat;
                    background-size: 100%;
                    transition: all 0.5s;

                    @include to(800) {
                        top: 1px;
                        right: 5px;
                        width: 8px;
                        height: 16px;
                    } 
                }

                &:hover {
                    color: $c-bronze-gold;
                }
                

                &.active-title {
                    font-weight: 700;
                    color: $c-bronze-gold !important;
                    

                    &:after {
                        right: -25px;
                        transform: rotate(90deg);

                        @include to(800) {
                            right: 5px;
                        } 
                    }
                }

                @include to(1660) {
                    font-size: 14px;
                    line-height: 18px;
                }
                @include to(800) {
                    font-size: 14px;
                    line-height: 16px;
                    display: block;
                    padding-right: 20px;
                }
            }

            &__icon {
                width: 10px;
            }

            &__wrap {
                transition: max-height 0.5s ease-in;
                overflow: hidden;
                max-height: 0; 
            }

            &__desc {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                padding-top: 20px; 

                @include to(800) {
                    font-size: 12px;
                    line-height: 16px;
                } 
            }

            &__desc-elem {
                padding-left: 20px;
                position: relative;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                list-style-type: none;

                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                &:before {
                    position: absolute;
                    content: '';
                    top: 10px;
                    left: 0;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: $c-dark-gray;
                }
            }

            &_theme {
                &_dark {

                    .list-details__item {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    }
                    .list-details__title {
                        color: #fff;

                        &:hover {
                            color: $c-bronze-gold;
                        }
                    }
                    .list-details__desc {
                        color: #fff;
                    }
                }
            } 
        }
    }
}