.section-ten {
  padding: 150px 185px 85px 185px;

  @include to(768) {
    padding: 40px 20px;
  }

  &__cars {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    @include to(768) {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }

  &__car-info {
    &:first-child {
      margin-right: 200px;

      @include to(768) {
        margin-right: 0;
      }
    }

    h3 {
      margin-bottom: 35px;
      text-transform: uppercase;
      font-size: 50px;
      font-weight: 300;
      text-align: center;

      @include to(768) {
        font-size: 25px;
      }
    }

    div {
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      color: $c-bronze-gold;
    }
  }

  &__image {
    margin-bottom: 55px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    @include to(768) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
    }
  }

  .btn {
    border: 1px solid $c-txt;

    @include to(768) {
      max-width: 177px;
    }

    &:first-child {
      margin-right: 30px;
      background-color: $c-txt;
      color: #fff;

      @include to(768) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  &__text {
    color: $c-gray-medium;
    font-size: 12px;
    text-align: center;

    @include to(768) {
      font-size: 10px;
    }
  }

  &__line {
    display: none;
    width: 100vw;
    background-color: #dedede;
    height: 1px;
    transform: translateX(-20px);
    margin-bottom: 40px;

    @include to(768) {
      display: block;
    }
  }
}
