.card {
    display: flex;
    flex-direction: column;
    width: calc(25% - 16px);
    margin: 8px;
    background: $c-base;
    @include to(1400) {
        width: calc(33.3333% - 16px);
    }
    @include to(1000) {
        width: calc(50% - 16px);
    }
    @include to(474) {
        width: calc(100% - 16px);
    }
    &.hidden-more {
        display: none;
    }
    &__image {
        position: relative;
        width: 100%;
        padding-top: 67%;
        overflow: hidden;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }
    &__content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 10px 0;
        padding: 0 1rem 0;
    }
    &__name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    &__text {
        font-size: 14px;
        font-weight: 300;
        text-align: center;
        margin-bottom: 15px;
    }
    &__description p {
        font-size: 10px;
        font-weight: 300;
        margin-bottom: 5px;
    }
    &__price {
        font-size: 24px;
        // margin-bottom: 15px;
        position: relative;
        top: -12px;
        text-decoration: line-through;
        font-weight: 700;
        @include to(1100) {
            top: 0;
        }
        @include from(1920) {
            font-size: 24px!important;
        }
        &__container {
            display: flex;
            flex-flow: column;
            text-align: right;
            margin-bottom: 5px;
            order: 0;
            border-left: 22px transparent solid;
            p {
                font-weight: 700;
                @include to(1920) {
                    font-size: 10px;
                }
                @include from(1920) {
                    font-size: 1rem;
                }
            }
        }
    }
    .btn {
        margin-top: auto;
    }
}