.section-nine {
  width: 100vw;
  height: 100vh;
  background-image: url("/static/img/lx/section-9.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 257px;
  display: flex;
  align-items: center;

  @include to(480) {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%),
      url("/static/img/lx/mobile/9.jpg");
  }

  @include to(768) {
    padding: 80px 20px;
  }

  .section-info {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include to(768) {
      height: 100%;
    }

    .btn {
      max-width: 250px;

      @include to(768) {
        margin: auto auto 0;
        background-color: transparent;
        color: #fff;
      }
    }

    h2 {
      @include to(768) {
        font-size: 30px;
        text-align: center;
      }
    }

    p {
      display: inline-block;

      @include to(768) {
        display: none;
      }
    }
  }

  .section-info {
    margin-left: 0;
  }
}
