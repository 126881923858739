@import "~animate.css/animate.min.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;600&display=swap");
@import "vars";
@import "sections/general";
@import "elements/general";
@import "lx/general";
// @import "dark-night/general";
// @import "./node_modules/bootstrap/scss/functions";
// @import "./node_modules/bootstrap/scss/variables";
// @import "./node_modules/bootstrap/scss/mixins";
// @import "./node_modules/bootstrap/scss/grid";
// @import "./node_modules/bootstrap/scss/utilities";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body > main > section:nth-child(2) > div > div > form > label.error {
    @apply text-white
  }

  body > svg {
    @apply contents
  }
}