.logo {
  display: flex;
  align-items: center;

  &__img {
    width: 120px;
    margin-right: 40px;
  }

  &__title {
    padding-left: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    color: #fff;
    position: relative;
    letter-spacing: 1px;

    @include to(1550) {
      padding-left: 20px;
    }
    @include to(414) {
      font-size: 10px;
    }

    span {
      // display: block;
      font-size: 10px;
      font-weight: 300;
    }

    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   left: 15px;
    //   background: $c-bronze-gold;
    //   height: 40px;
    //   width: 1px;
    // }
  }
}
