.insurance {
  padding-bottom: 40%;
  background-image: url('/static/img/credit/desktop_hu35ddafce051cd989919ccd5cec9491c0_5223140_1920x0_resize_q80_lanczos.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  @include to(1000) {
    padding-bottom: 0;
    height: 730px;
  }

  .container {
    position: relative;
  }

  &__block {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(50%);

    @include to(1300) {
      transform: translateY(10%);
    }

    @include to(1000) {
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 0;
      left: 50%;
      right: auto;
      transform: translateY(10%) translateX(-50%);
    }
  }

  &__title {
    font-size: 54px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 8px;

    @include to(1000) {
      font-size: 28px;
    }
  }

  &__description {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 30px;

    @include to(1000) {
      font-size: 14px;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 20px;

    li {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;

      @include to(1000) {
        font-size: 16px;
      }

      span {
        color: #C78E66;
      }

      &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        background-image: url('/static/img/icons/check.svg');
        margin-right: 10px;
      }
    }
  }

  .btn {
    width: 170px;
  }
}