.breadcrumbs {
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-bottom: 9px;
  margin: 30px 0 0;

  &-wrapper {
    width: 100%;
    overflow-x: hidden;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: italic;
    white-space: nowrap;

    &:last-child {
      font-weight: 700;
      pointer-events: none;
    }
  }

  img {
    width: auto;
    height: 10px;
    margin: 0 5px;
  }
}