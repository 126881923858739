.section__terms {
    position: relative;
    background: url(../img/test-drive.jpg) center no-repeat;
    background-size: cover;
    padding-top: 8%;
    &_black {
        width: 100%;
        height: 100%;
        background-color: rgba(24, 27, 29, 0.5);
        z-index: -1;
        position: absolute;
        top: 0;
    }
    &_content {
        // position: absolute;
        // top: 100px;
        // left: 50%;
        // -webkit-transform: translateX(-50%);
        // transform: translateX(-50%);
        width: 100%;
        padding: 0 150px;
        z-index: 20;
    }
    @include to(1200) {
        &_content {
            padding: 0;
        }
        
    }
    &_title {
        text-transform: uppercase;
        font-size: 60px;
        color: #FFFFFF;
        font-weight: 300;
        text-align: center;
    }
    @include to(1200) {
        &_title {
            font-size: 40px;
        }
    }
    &_wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 110px;
        margin-top: 150px;
        padding-bottom: 150px;
    }
    &_item {
        position: relative;
        margin: 0 auto;
        text-align: center;
        &-title {
            font-weight: 300;
            font-size: 40px;
            color: #FFF;
            margin-top: 40px;
            text-transform: uppercase;
        }
        &-descr {
            font-weight: normal;
            font-size: 18px;
            color: #FFF;
            margin-top: 20px;
            margin-bottom: 50px;
            line-height: 150%;
            padding-bottom: 56px;
        }
        @include to(1200) {
            &-descr {
                padding: 0 20px 56px 20px;
            }
        }
        &-btn {
            margin: 0;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
        }
    }
    @include to(800) {
        &_wrap {
            margin-top: 30px;
        }
        
    }
    &_img {
        position: relative;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        background: #C78E66;
        border-radius: 50px;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .slick-dots {
        bottom: 37px !important;
        .slick-active {
            button{
                &:before {
                    color: #C78E66;
                    opacity: 1;
                }
            }
        }
        button{
            &:before {
                color: #FFFFFF;
                opacity: 0.2;
            }
        }

        
        
    }
}

// @include to(1650) {
//     .section-terms{
//         padding-top: 55%;
//     } 
// }
// @include to(1500) {
//     .section-terms{
//         padding-top: 60%;
//     } 
// }

// @include to(1400) {
//     .section-terms{
//         padding-top: 70%;
//     } 
// }

// @include to(1300) {
//     .section__terms_wrap {
//         margin-top: 70px;
//     } 
//     .section__terms_title {
//         font-size: 40px;
//     }
//     .section__terms_item-title {
//         font-size: 30px;
//     }
//     .section__terms_item-title {
//         font-size: 14px;
//     }
//     .section__terms_item-title {
//         top: 70px;
//     }
// }

