.hero-video{ 
        &__content {
        display: none;
            @include from (1025) {
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                padding: 60px 20px 30px 13.4%;
                text-align: left;
                color: #fff
            }
        
    }
    &__title {
            font-size: 40px
        }
        &__text {
            font-size: 24px;
            margin-top: 30px
        }
        &__price {
            font-size: 30px;
            line-height: 1;
            margin-top: 30px
        }
}