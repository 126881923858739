.section-auto {
  padding: 100px 0;
  background: $c-gray-back;

  @include to(800) {
    padding: 40px 0;
  }

  &__title {
    margin-bottom: 100px;
    text-align: center;

    @include to(800) {
      margin-bottom: 40px;
    }
  }

  .slick-list {
    @include to(800) {
      padding: 0 10% 0 0 !important;
    }

    padding: 0 20% 0 0 !important;
  }

  .auto-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    justify-content: space-between;
    width: 100%;
    &__item {
      width: 100%;
      background-color: #fff;
      &-content {
        padding: 20px;
        button {
          margin-top: 30px;
        }
      }
      &-title {
        font-size: 40px;
        text-transform: uppercase;
      }
      @include to(800) {
        &-title {
          font-size: 20px;
        }
        &-price {
          font-size: 14px;
        }
      }
      &-price {
        font-weight: normal;
        font-size: 18px;
        color: #7d8082;
        margin-top: 10px;
      }
      &-skill {
        margin-top: 30px;
        .skill__item {
          display: grid;
          grid-template-columns: auto 1fr;
          padding-bottom: 10px;
          border-bottom: 1px solid #dedede;
          margin-bottom: 10px;
          img {
            height: 18px;
            width: 10px;
            margin-right: 10px;
          }
          div {
            font-weight: normal;
            font-size: 14px;
            color: #30363a;
          }
        }
      }
    }
  }
  @include to(1200) {
    .auto-list {
      display: block;
      overflow: hidden;
      &__item {
        margin-left: 20px;
      }

      &__item-img img {
        width: 100%;
      }
    }
  }
}
